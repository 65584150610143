import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, styled } from '@mui/material';
import { LanguageContext } from 'services/localization/LanguageContext';
import CheckboxList from '../../../components/inputs/CheckboxList';
import { getCommon } from '../../../commons/index';

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const ConsentStep = ({ state, setState }) => {
  const { translate, lang } = useContext(LanguageContext);
  const { pagesUrl } = getCommon();
  useEffect(() => {
    document.getElementById('nav-back').focus();
  }, []);

  return (
    <>
      {state.digitalRequested && (
        <>
          <Title tabIndex="0" variant="subtitle3">
            {translate('pages.request.consent.manitobaClauseConsentTitle')}
          </Title>

          <CheckboxList
            state={state.consentState}
            setState={setState.setConsentState}
          />
          <br />
          <Typography
            tabIndex="0"
            variant="subtitle2"
            aria-label={translate(
              'pages.request.consent.readPrivacy.readPrivacyAriaLabel',
            )}
          >
            {translate('pages.request.consent.readPrivacy.read', {
              privacyPolicy: (
                <Link
                  key="privacyPolicy"
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                  component={RouterLink}
                  to={`${
                    pagesUrl.get('PrivacyNoticeAndConsent').url
                  }?lang=${lang}`}
                >
                  pages.request.consent.readPrivacy.privacyPolicy
                </Link>
              ),
            })}
          </Typography>
        </>
      )}
      {state.canadianCertificateRequested && (
        <>
          <br />

          <CheckboxList
            state={state.canadianConsentState}
            setState={setState.setCanadianConsentState}
          />
          <br />
          <Typography tabIndex="0" variant="subtitle2">
            {translate('pages.request.consent.nationalPVC_Note', {})}
          </Typography>
          <br />
          <Typography
            tabIndex="0"
            variant="subtitle2"
            aria-label={translate(
              'pages.request.consent.readPrivacy.readPrivacyAriaLabel',
            )}
          >
            {translate('pages.request.consent.readPrivacy.read', {
              privacyPolicy: (
                <Link
                  key="privacyPolicy"
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                  component={RouterLink}
                  to={`${
                    pagesUrl.get('PrivacyNoticeAndConsent').url
                  }?lang=${lang}`}
                >
                  pages.request.consent.readPrivacy.privacyPolicy
                </Link>
              ),
            })}
          </Typography>
        </>
      )}
    </>
  );
};

ConsentStep.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setState: PropTypes.object.isRequired,
};

export default ConsentStep;
