import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LanguageContext } from 'services/localization/LanguageContext';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import pagesUrl from 'commons/config/PagesUrl';
import NavigationDrawer from './NavigationDrawer';
import NavBarLogo from './NavBarLogo';
import { ReactComponent as EditCard } from '../../img/edit-card.svg';

const Root = styled('div')(() => ({
  display: 'flex',
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: theme.Header.height,
  backgroundColor: theme.palette.primary.main,
  boxShadow: 'none',
  // use this property to cover the top of permanent drawer
  zIndex: theme.zIndex.drawer + 1,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const DivToolbar = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const LanguageLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'alwaysShowLanguageToggle',
})(({ alwaysShowLanguageToggle, theme }) => ({
  textDecoration: 'underline',
  color: 'white',
  display: alwaysShowLanguageToggle ? 'inline-block' : 'none',

  [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
    display: 'inline-block',
    marginLeft: 'auto',
  },
}));

const MainContent = styled('main')(() => ({
  flexGrow: 1,
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: theme.spacing(1),
    fontSize: '0.85em',
    fontFamily:
      'Nunito Sans,Arial,-apple-system,BlinkMacSystemFont,Roboto,sans-serif',
    padding: '10px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
  },
}));

const NavigationBar = (props) => {
  const { children, showAddButton, showIconButton, alwaysShowLanguageToggle } =
    props;
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { translate, toggleLang } = useContext(LanguageContext);
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    const closeDrawer = () => setDrawerOpen(false);
    window.addEventListener('resize', closeDrawer);
    return () => window.removeEventListener('resize', closeDrawer);
  }, []);

  const handleDrawerToggle = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  let showAddIcon =
    !location.pathname.startsWith(pagesUrl.get('VaxCardRequest').url) ||
    location.pathname === pagesUrl.get('Confirmation').url;
  if (!showAddButton) {
    showAddIcon = false;
  }

  const tooltipText = translate('layout.nav.requestCard');

  return (
    <>
      <StyledAppBar position="relative">
        <StyledToolbar>
          <Hidden {...theme.navBarBreakpoint.hideDrawerButton}>
            {showIconButton && (
              <MenuButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => handleDrawerToggle(!isDrawerOpen)}
              >
                <MenuIcon />
              </MenuButton>
            )}
          </Hidden>

          <NavBarLogo isWhiteLogo />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <LanguageLink
            component="button"
            onClick={toggleLang}
            alwaysShowLanguageToggle={alwaysShowLanguageToggle}
          >
            {translate('layout.otherLanguage')}
          </LanguageLink>
          {showAddIcon && (
            <StyledTooltip title={tooltipText} aria-label={tooltipText} arrow>
              <MenuButton
                color="inherit"
                aria-label={tooltipText}
                edge="end"
                component={RouterLink}
                to={pagesUrl.get('VaxCardRequest').url}
                replace
              >
                <EditCard
                  width="24px"
                  height="24px"
                  className="bi bi-person-plus"
                />
              </MenuButton>
            </StyledTooltip>
          )}
        </StyledToolbar>
      </StyledAppBar>
      {isDrawerOpen && (
        <NavigationDrawer
          isOpen={isDrawerOpen}
          onClose={() => handleDrawerToggle(false)}
        />
      )}

      <Root>
        <MainContent>
          <DivToolbar />
          {children}
        </MainContent>
      </Root>
    </>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  showAddButton: PropTypes.bool,
  showIconButton: PropTypes.bool,
  alwaysShowLanguageToggle: PropTypes.bool,
};

NavigationBar.defaultProps = {
  children: null,
  showAddButton: true,
  showIconButton: true,
  alwaysShowLanguageToggle: false,
};

export default NavigationBar;
