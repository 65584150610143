import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CssBaseline,
  Divider,
  List,
  Drawer,
  Link,
  Hidden,
  useTheme,
  styled,
} from '@mui/material';
import { LanguageContext } from 'services/localization/LanguageContext';
import NavigationBarItem from './NavigationBarItem';
import { getCommon } from '../../commons/index';
import NavBarLogo from './NavBarLogo';
import NavigationBarItemGroup from './NavigationBarItemGroup';

const Root = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  minHeight: theme.Header.height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2),
}));

const DrawerItems = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .MuiDrawer-paper`]: {
    width: theme.drawer.width,
    maxWidth: '90vw',
    [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
      position: 'relative',
    },
  },
  [`& .MuiDrawer-root`]: {
    width: theme.drawer.width,
  },
}));

const LogoContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const LanguageLink = styled(Link)(({ theme }) => ({
  display: 'block',
  textDecoration: 'underline',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
    display: 'none',
  },
}));

const NavigationDrawer = ({ isOpen, onClose, variant }) => {
  const { getGroup } = getCommon();
  const menuSections = [getGroup(1), getGroup(2), getGroup(3)];
  const { translate, toggleLang } = useContext(LanguageContext);
  const theme = useTheme();

  const getMenuSection = (menuItems) =>
    menuItems
      .map((item) => {
        if (item.subMenu) {
          return (
            <NavigationBarItemGroup
              key={item.showName}
              Icon={item.icon}
              subMenu={item.subMenu}
              text={item.showName}
              onClick={onClose}
            />
          );
        }
        return (
          <NavigationBarItem
            key={item.showName}
            Icon={item.icon}
            text={item.showName}
            linkTo={item.url}
            onClick={onClose}
            newPage={Boolean(item.newPage)}
          />
        );
      })
      // Removing nulls
      .filter((item) => item);

  const menuJsx = menuSections.map((menuItems, index) => {
    const menuSection = getMenuSection(menuItems);
    if (!menuSection || menuSection.length === 0) {
      return null;
    }
    return (
      <Fragment key={menuItems[0].showName}>
        <List>{menuSection}</List>
        {index < menuSections.length - 1 && <Divider />}
      </Fragment>
    );
  });

  const drawer = (
    <Root>
      <Hidden {...theme.navBarBreakpoint.hideDrawerButton}>
        <DrawerHeader>
          <LogoContainer>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <LanguageLink component="button" onClick={toggleLang}>
              {translate('layout.otherLanguage')}
            </LanguageLink>
            <NavBarLogo />
          </LogoContainer>
        </DrawerHeader>
      </Hidden>
      <Divider />
      <DrawerItems>
        <div>{menuJsx}</div>
      </DrawerItems>
    </Root>
  );

  return (
    <>
      <CssBaseline />
      <StyledDrawer
        variant={variant}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={isOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </StyledDrawer>
    </>
  );
};

export default NavigationDrawer;
NavigationDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,

  variant: PropTypes.string,
};
NavigationDrawer.defaultProps = {
  variant: 'temporary',
  onClose: null,
};
