import React from 'react';
import { Typography, styled } from '@mui/material';
import QRCode from 'react-qr-code';
import mbLogoGreen from 'img/mb-logo.png';
import PropTypes from 'prop-types';

const Root = styled('div')(() => ({
  width: '3.375in',
  height: '2.125in',
  border: '1px solid black',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
}));

const PrintableCardHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '5px 15px',
}));

const PrintableCardHeaderText = styled(Typography)(() => ({
  fontSize: '10px',
  fontStyle: 'var(--unnamed-font-style-normal)',
  fontVariant: 'normal',
  fontWeight: 'var(--unnamed-font-weight-normal)',
}));

const PrintableCardContent = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

const PrintableCardContentText = styled(Typography)(() => ({
  fontSize: '13px',
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  textAlign: 'center',
}));

const PrintableCard = React.forwardRef(
  ({ firstName, lastName, certificateId }, ref) => {
    const fullNameLength = firstName.length + lastName.length;

    return (
      <Root ref={ref}>
        <PrintableCardHeader>
          <div>
            <PrintableCardHeaderText>Immunization Card</PrintableCardHeaderText>
            <PrintableCardHeaderText>
              Carte d&apos;immunisation
            </PrintableCardHeaderText>
          </div>
          <div id="imgContainer">
            <img
              src={mbLogoGreen}
              alt="Manitoba Government"
              width={75}
              height={15}
            />
          </div>
        </PrintableCardHeader>
        <hr style={{ margin: '0 15px' }} />
        <div style={{ width: '100%' }}>
          <PrintableCardContent
            style={{ marginTop: fullNameLength < 41 ? '10px' : '0px' }}
          >
            <PrintableCardContentText>
              {firstName} {lastName}
            </PrintableCardContentText>
          </PrintableCardContent>
          <PrintableCardContent
            style={{ marginTop: fullNameLength < 41 ? '10px' : '0px' }}
          >
            <QRCode size={110} value={certificateId} />
          </PrintableCardContent>
        </div>
      </Root>
    );
  },
);

export default PrintableCard;

PrintableCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  certificateId: PropTypes.string.isRequired,
};
