import React, { useEffect, useState } from 'react';
import { Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { isEllipsisActive } from '../../../utility/osUtility';

const Name = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '1.75em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const StyledQRCode = styled(QRCode)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const getShowStyle = (firstName, lastName) => {
  const characterLenLimit = 30;
  const differenceLimit = 7;
  const returnValue = [firstName, lastName];

  // criteria to show in two lines:
  // 1.firstName.length+lastName.length>30
  // 2.Math.abs(firstName.length-lastName.length)>=7
  // 3.Doesn't have enough room to show in the single line
  // Regarding judge if the space is enough, the basic idea is:
  // 1. render the first/last name in a single line first
  // (PS. the name doesn't match criteria which require to show in 2 lines)
  // 2. check the space in useEffect
  // 3. rerender to two separated lines if the space is not enough.
  if (firstName.length > characterLenLimit) {
    returnValue[0] = `${firstName.substring(0, 30)}...`;
  }
  if (lastName.length > characterLenLimit) {
    returnValue[1] = `${lastName.substring(0, 30)}...`;
  }
  if (lastName.length + firstName.length > 30) {
    return returnValue;
  }
  if (Math.abs(firstName.length - lastName.length) >= differenceLimit) {
    return returnValue;
  }
  return [`${firstName} ${lastName}`];
};

const CardTab = (props) => {
  const [hasEnoughSpace, setHasEnoughSpace] = useState(true);
  const [sizeChanged, setSizeChanged] = useState(false);
  const { certificate } = props;
  const firstName = certificate.firstName.toUpperCase();
  const lastName = certificate.lastName.toUpperCase();
  useEffect(() => {
    const calculateNameShownStyle = () => {
      const names = getShowStyle(firstName, lastName);
      if (names.length === 1) {
        // show in the same line, but ellips is shown, break into two lines
        const element = document.getElementById(`${names[0]}_0`);
        if (element && isEllipsisActive(element)) {
          setHasEnoughSpace(false);
        }
      }
    };
    calculateNameShownStyle();
    const notifySizeChanged = () => {
      // reset to show the name in a single line if it's possible,
      // after render, useEffect will change the style
      // if space is not enough
      setHasEnoughSpace(true);
      setSizeChanged(!sizeChanged);
    };
    window.addEventListener('resize', notifySizeChanged);
    return () => {
      window.removeEventListener('resize', notifySizeChanged);
    };
  }, [firstName, lastName, sizeChanged]);
  if (!certificate) {
    return null;
  }

  const url = `${certificate.certificateId}`;
  let names = getShowStyle(firstName, lastName);
  if (!hasEnoughSpace) {
    names = [firstName, lastName];
  }
  return (
    <>
      {names.map((name, index) => {
        return (
          <Name
            variant="h4"
            key={`${name}-${index.toString()}`}
            id={`${name}_${index.toString()}`}
          >
            {name}
          </Name>
        );
      })}

      <StyledQRCode size={225} value={url} />
    </>
  );
};
CardTab.propTypes = {
  certificate: PropTypes.shape({
    vaccinationDate: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    certificateId: PropTypes.string,
  }).isRequired,
};

export default CardTab;
