/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-04-19 09:53:11
 */
import React from 'react';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import pagesUrl from 'commons/config/PagesUrl';

export default {
  wpgPhone: (
    <Link key="wpgPhone" href="tel:204-786-7101">
      204-786-7101
    </Link>
  ),
  tollFreePhone: (
    <Link key="tollFree" href="tel:1-800-392-1207">
      1-800-392-1207
    </Link>
  ),
  deafAccessPhone: (
    <Link key="deafAccess" href="tel:204-774-8618">
      204-774-8618
    </Link>
  ),
  mbHealthCardLink: (
    <Link
      key="mbHealthCardLink"
      target="_blank"
      href="https://www.gov.mb.ca/health/mhsip/mbcard.html"
    >
      https://www.gov.mb.ca/health/mhsip/mbcard.html
    </Link>
  ),
  mbHealthCardLinkFr: (
    <Link
      key="mbHealthCardLinkFr"
      target="_blank"
      href="https://www.gov.mb.ca/health/mhsip/mbcard.fr.html"
    >
      https://www.gov.mb.ca/health/mhsip/mbcard.fr.html
    </Link>
  ),
  mbHealthEmail: (
    <Link key="mbHealthEmail" href="mailto:insuredben@gov.mb.ca">
      Insuredben@gov.mb.ca
    </Link>
  ),
  immunizationCardLink: (
    <Link
      key="immunizationCardLink"
      component={RouterLink}
      to={pagesUrl.get('DigitalCards').url}
    >
      immunizationcard.manitoba.ca
    </Link>
  ),
  contactEmail: (
    <Link
      key="contactEmail"
      href="mailto:ImmunizationCardSupport@gov.mb.ca"
      underline="always"
      style={{ wordWrap: 'break-word' }}
    >
      immunizationcardsupport@gov.mb.ca
    </Link>
  ),
  healthndSeniorsCareWebsite: (
    <Link
      key="healthndSeniorsCareWebsite"
      target="_blank"
      href="https://forms.gov.mb.ca/notice-of-change/index.html"
    >
      https://forms.gov.mb.ca/notice-of-change/index.html
    </Link>
  ),
  shareHealthLink: (
    <Link
      key="shareHealthLink"
      target="_blank"
      href="https://sharedhealthmb.ca/covid19/test-results/"
    >
      sharedhealthmb.ca/covid19/test-results/
    </Link>
  ),
  immunizationManitobaLink: (
    <Link
      key="immunizationManitobaLink"
      target="_blank"
      component={RouterLink}
      to={`${pagesUrl.get('DigitalCards').url}?lang=en`}
    >
      immunizationcard.manitoba.ca
    </Link>
  ),

  healthndSeniorsCareWebsiteFr: (
    <Link
      key="healthndSeniorsCareWebsiteFr"
      target="_blank"
      href="https://forms.gov.mb.ca/notice-of-change/index.fr.html"
    >
      https://forms.gov.mb.ca/notice-of-change/index.fr.html
    </Link>
  ),
  shareHealthLinkFr: (
    <Link
      key="shareHealthLinkFr"
      target="_blank"
      href="https://covid19.soinscommunsmb.ca/covid19/resultats-test/"
    >
      covid19.soinscommunsmb.ca/covid19/resultats-test
    </Link>
  ),
  // eslint-disable-next-line max-len
  // TODO current, the French url is not ready, come back here when the French url is ready.
  immunizationManitobaLinkFr: (
    <Link
      key="immunizationManitobaLinkFr"
      target="_blank"
      component={RouterLink}
      to={`${pagesUrl.get('DigitalCards').url}?lang=fr`}
    >
      immunizationcard.manitoba.ca
    </Link>
  ),
  recordLinkEn: (
    <Link
      key="shareHealthLinkEn"
      target="_blank"
      href="https://manitoba.ca/covid19/vaccine/immunization-record.html"
    >
      https://manitoba.ca/covid19/vaccine/immunization-record.html
    </Link>
  ),
  recordLinkFr: (
    <Link
      key="shareHealthLinkFr"
      target="_blank"
      href="https://manitoba.ca/covid19/vaccine/immunization-record.fr.html"
    >
      manitoba.ca/covid19/vaccine/immunization-record.fr.html
    </Link>
  ),
  forResidentsEn: (
    <Link
      key="forResidentsEn"
      target="_blank"
      underline="always"
      href="https://manitoba.ca/covid19/vaccine/immunizationrecord/residents.html"
    >
      Province of Manitoba | For Residents
    </Link>
  ),
  forResidentsFr: (
    <Link
      key="forResidentsFr"
      target="_blank"
      underline="always"
      href="https://manitoba.ca/covid19/vaccine/immunizationrecord/residents.fr.html"
    >
      Province du Manitoba | Pour les résidents
    </Link>
  ),
  recordLinkUnderline: (
    <Link
      key="recordLinkUnderline"
      target="_blank"
      underline="always"
      href="https://manitoba.ca/covid19/vaccine/immunization-record.html"
    >
      https://manitoba.ca/covid19/vaccine/immunization-record.html
    </Link>
  ),
  appleAppStoreLink: (
    <Link
      key="appleAppStoreLink"
      target="_blank"
      underline="always"
      href="https://apps.apple.com/ca/app/manitoba-immunization-card/id1574100146"
    >
      Apple App Store
    </Link>
  ),
  appleAppStoreLinkFr: (
    <Link
      key="appleAppStoreLinkFr"
      target="_blank"
      underline="always"
      href="https://apps.apple.com/ca/app/manitoba-immunization-card/id1574100146"
    >
      boutique Apple
    </Link>
  ),
  googlePlayStoreLink: (
    <Link
      key="googlePlayStoreLink"
      target="_blank"
      underline="always"
      href="https://play.google.com/store/apps/details?id=com.govmb.immunizationrecord"
    >
      Google Play Store
    </Link>
  ),
  googlePlayStoreLinkFr: (
    <Link
      key="googlePlayStoreLinkFr"
      target="_blank"
      underline="always"
      href="https://play.google.com/store/apps/details?id=com.govmb.immunizationrecord"
    >
      Google Play
    </Link>
  ),
};
