import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { CertificatesProvider } from 'services/certificates/CertificatesProvider';
import { FeatureContext } from '../../services/common/azure/FeatureContext';
import { LoginContext } from '../../services/login/LoginContext';
import PageLoading from '../page-loading/PageLoading';
import { RouterRole } from '../../RouterConfig';

const getRouterJSX = (routerConfig) => {
  const onlyPrivate = routerConfig.filter((rt) => {
    return rt.role === RouterRole.PROTECTED;
  });
  return (
    <CertificatesProvider>
      <Switch>
        {onlyPrivate.map((router) => {
          return (
            <Route
              key={router.path}
              path={router.path}
              exact={router.exact}
              component={router.component}
            />
          );
        })}
      </Switch>
    </CertificatesProvider>
  );
};
const RouterManage = ({ routerConfig: _routerConfig }) => {
  const loginContext = useContext(LoginContext);
  const { checkFeatureEnabled } = useContext(FeatureContext);
  const isAuth = loginContext.isAuthenticated();
  if (!isAuth) {
    return <PageLoading />;
  }

  // Need to exclude feature flag that is turned off.
  const routerConfig = _routerConfig.filter((router) => {
    if (router.featureFlag && !checkFeatureEnabled(router.featureFlag)) {
      return null;
    }

    return router;
  });

  return getRouterJSX(routerConfig);
};

export default RouterManage;

RouterManage.propTypes = {
  routerConfig: PropTypes.instanceOf(Object),
};
RouterManage.defaultProps = {
  routerConfig: {},
};
