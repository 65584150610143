import React from 'react';
import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledSpan = styled('span')({
  padding: '10px',
  alignItems: 'center',
  textAlign: 'center',
  marginRight: '20px',
});

const PageLoading = () => {
  return (
    <Root>
      <StyledSpan>
        <CircularProgress />
      </StyledSpan>
    </Root>
  );
};

export default PageLoading;
