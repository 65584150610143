// @flow
/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-28 11:39:14
 */
import React from 'react';
import { Redirect } from 'react-router-dom';
// import VenueHistory from './pages/venue-history/VenueHistory';
// import OutbreakAlerts from './pages/outbreak-alerts/OutbreakAlerts';
import LogoutPage from './pages/logout/LogoutPage';
import FAQProxyPage from './pages/public-faq/FAQProxyPage';
import VaxCardRequestStepper from './pages/vax-card-request-stepper/VaxCardRequestStepper';
import DigitalCards from './pages/digital-cards/DigitalCards';
import PrivacyNoticeAndConsent from './pages/pravicy-notice-consent/PrivacyNoticeAndConsent';
import PublicPrivatePage from './pages/public-private/PublicPrivatePage';
import { getCommon } from './commons/index';

const { pagesUrl } = getCommon();
export const RouterRole = {
  PUBLIC: 'public',
  PROTECTED: 'protected',
};
const routerConfig = [
  {
    path: pagesUrl.get('Help').url,
    component: () => <Redirect to={pagesUrl.get('FAQ').url} />,
    exact: true,
    role: RouterRole.PUBLIC,
  },
  {
    path: pagesUrl.get('FAQ').url,
    component: FAQProxyPage,
    exact: true,
    role: RouterRole.PUBLIC,
  },
  {
    path: pagesUrl.get('PrivateNotice').url,
    component: PrivacyNoticeAndConsent,
    exact: true,
    role: RouterRole.PUBLIC,
  },
  // {
  //   path: pagesUrl.get('PublicPrivate').url,
  //   component: PublicPrivatePage,
  //   exact: true,
  //   role: RouterRole.PUBLIC,
  // },
  {
    path: pagesUrl.get('DigitalCards').url,
    component: DigitalCards,
    exact: true,
    role: RouterRole.PROTECTED,
  },
  {
    path: pagesUrl.get('PrivacyNoticeAndConsent').url,
    component: PrivacyNoticeAndConsent,
    exact: true,
    role: RouterRole.PROTECTED,
  },
  {
    path: pagesUrl.get('VaxCardRequest').url,
    component: VaxCardRequestStepper,
    exact: false,
    role: RouterRole.PROTECTED,
  },
  {
    path: pagesUrl.get('TermsOfUse').url,
    component: PublicPrivatePage,
    exact: true,
    role: RouterRole.PUBLIC,
  },
  {
    path: pagesUrl.get('Logout').url,
    component: LogoutPage,
    exact: true,
    role: RouterRole.PROTECTED,
  },
  {
    path: '/',
    component: () => <Redirect to={pagesUrl.get('DigitalCards').url} />,
    role: RouterRole.PROTECTED,
  },
  // {
  //   path: pagesUrl.get('OutbreakAlerts').url,
  //   component: OutbreakAlerts,
  //   exact: true,
  // },
  // {
  //   path: pagesUrl.get('VenueHistory').url,
  //   component: VenueHistory,
  //   exact: true,
  // },

  // {
  //   path: '/test',
  //   component: TestPage,
  //   exact: true,
  // },
  // {
  //   path: '*',
  //   component: DigitalCards,
  //   exact: true,
  //   role: 'protected',
  // },
];
export function getRouteInformationByRole(name) {
  const rout = routerConfig.filter((rt) => {
    return rt.role === name;
  });
  return rout;
}

export default routerConfig;
