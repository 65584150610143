import React from 'react';
import PropTypes from 'prop-types';
import { Button, styled } from '@mui/material';

const CustomButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const CustomizedButton = (props) => {
  const { children, className } = props;
  return (
    <CustomButton {...props} className={className}>
      {children}
    </CustomButton>
  );
};

CustomizedButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CustomizedButton.defaultProps = {
  className: '',
  children: '',
};

export default CustomizedButton;
