import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useStore from 'services/store';
import NavigationBarItem from './NavigationBarItem';

const NavigationBarItemGroup = ({ Icon, subMenu, text, onClick }) => {
  /* Since the parent layout component is unmounted and remounted on every route
    change, internal state is lost. Using a global store to help manage this.
    If other sub menus are added the state management will need to be updated
    to allow for this. */
  const servicesMenuOpen = useStore((state) => state.servicesMenuOpen);
  const toggleServicesMenu = useStore((state) => state.toggleServicesMenu);

  const subMenuItems = subMenu.map((item) => (
    <NavigationBarItem
      key={item.showName}
      Icon={item.icon}
      text={item.showName}
      linkTo={item.url}
      onClick={onClick}
      newPage={Boolean(item.newPage)}
    />
  ));

  return (
    <>
      <NavigationBarItem
        Icon={Icon}
        text={text}
        onClick={toggleServicesMenu}
        endIcon={servicesMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      />
      <Collapse in={servicesMenuOpen}>{subMenuItems}</Collapse>
    </>
  );
};

export default NavigationBarItemGroup;

NavigationBarItemGroup.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      showInNavBar: PropTypes.bool,
      showName: PropTypes.string,
      icon: PropTypes.element,
      newPage: PropTypes.bool,
    }),
  ).isRequired,
  onClick: PropTypes.func,
};

NavigationBarItemGroup.defaultProps = {
  onClick: null,
  Icon: null,
};
