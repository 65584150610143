import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, styled } from '@mui/material';
import { LanguageContext } from 'services/localization/LanguageContext';
import CheckboxList from 'components/inputs/CheckboxList';

const Title = styled(Typography)(() => ({
  fontWeight: '600',
  paddingBottom: '5px',
  textAlign: 'center',
}));

const ContentText = styled(Typography)(() => ({
  font: 'normal normal normal 16px/24px Nunito Sans',
  textAlign: 'left',
  letterSpacing: '0px',
}));

const BoxFrame = styled('div')(({ theme }) => ({
  border: '2px solid #363636',
  borderRadius: '5px',
  marginTop: theme.spacing(2),
  padding: '10px 16px',
  marginBottom: '4px',
}));

const Requests = ({ state, setState }) => {
  const { translate } = useContext(LanguageContext);

  useEffect(() => {
    document.getElementById('nav-back').focus();
  }, []);

  return (
    <>
      <ContentText tabIndex="0" variant="subtitle2">
        {translate('pages.request.requests.belowAre')}
      </ContentText>

      <BoxFrame>
        <Title tabIndex="0" variant="body1">
          {translate('pages.request.requests.canadianCertificate')}
        </Title>

        <ContentText tabIndex="0" variant="body1">
          {translate('pages.request.requests.canadianPVCDescription')}
        </ContentText>

        <CheckboxList
          state={state.requestsPDFState}
          setState={setState.setRequestsPDFState}
        />
      </BoxFrame>
      <br />
      {/* <BoxFrame>
        <Title tabIndex="0" variant="body1">
          {translate('pages.request.requests.manitobaImmunization')}
        </Title>

        <ContentText tabIndex="0" variant="body1">
          {translate('pages.request.requests.MBCardDescription')}
        </ContentText>
        <CheckboxList
          state={state.requestsCardState}
          setState={setState.setRequestsCardState}
        />
      </BoxFrame> */}
    </>
  );
};

Requests.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setState: PropTypes.object.isRequired,
};

export default Requests;
