import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, styled } from '@mui/material';
import links from 'constants/links';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../services/localization/LanguageContext';
import { getLanguage } from '../../utility/languageUtility';

const linkObject = {
  healthndSeniorsCareWebsite: links.healthndSeniorsCareWebsite,
  contactEmail: links.contactEmail,
  shareHealthLink: links.shareHealthLink,
  immunizationManitobaLink: links.immunizationManitobaLink,
  healthndSeniorsCareWebsiteFr: links.healthndSeniorsCareWebsiteFr,
  shareHealthLinkFr: links.shareHealthLinkFr,
  immunizationManitobaLinkFr: links.immunizationManitobaLinkFr,
  appleAppStoreLink: links.appleAppStoreLink,
  googlePlayStoreLink: links.googlePlayStoreLink,
  appleAppStoreLinkFr: links.appleAppStoreLinkFr,
  googlePlayStoreLinkFr: links.googlePlayStoreLinkFr,
  forResidentsEn: links.forResidentsEn,
  forResidentsFr: links.forResidentsFr,
};

const Root = styled('div')(() => ({
  width: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  fontWeight: 'bold',
  textAlign: 'center',
}));

const AccordionRoot = styled(Accordion)(() => ({
  '&:before': {
    backgroundColor: 'transparent!important',
  },
  boxShadow: 'none',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  boxShadow: '0px 3px 10px #00000012',
  border: '1px solid #C7D0D8',
  borderRadius: '9px',
  marginBottom: theme.spacing(2),
  background: '#F2F8F8C4 0% 0% no-repeat padding-box;',
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    order: -1,
    '&.Mui-expanded': {
      transform: 'rotate(90deg)!important',
    },
    marginRight: '-12px',
  },
}));

const StyledArrowRightIcon = styled(ArrowRightIcon)(({ theme }) => ({
  color: 'black',
  fontSize: 40,
  padding: theme.spacing(1.5),
  width: '32px',
  height: '32px',
}));

const QuestionCatalogTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const QuestionTitle = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '1.3em',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  flexDirection: 'column',
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '1.3em',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  padding: '8px 16px 16px',
  textAlign: 'center',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const AnswerText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '1.3em',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const ListText = styled(Typography)(() => ({
  padding: '0px',
  margin: '0px',
  fontSize: 16,
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
}));

const analyzeAnswer = (answerArray, languageContext) => {
  const { translate, lang } = languageContext;
  if (!answerArray || !(answerArray instanceof Array)) {
    return null;
  }
  const jsx = (
    <>
      {answerArray.map((answer, index) => {
        if (typeof answer === 'string') {
          return (
            <AnswerText key={`answer_${index.toString()}`}>
              {translate('answer', linkObject, { answer: { [lang]: answer } })}
            </AnswerText>
          );
        }
        if (typeof answer === 'object') {
          if (answer.list) {
            return (
              <ul key={`answer_${index.toString()}`}>
                {answer.list.map((li, liIndex) => {
                  return (
                    <li key={`answer_list_${liIndex.toString()}`}>
                      <ListText>{li}</ListText>
                    </li>
                  );
                })}
              </ul>
            );
          }
          return null;
        }
        return null;
      })}
      <br />
      <br />
    </>
  );

  return jsx;
};
const getQuestionsJsx = (questionArray, title, languageContext) => {
  const { translate } = languageContext;
  const jsx = questionArray.map((qa, index) => {
    return translate('question', null, qa) === 'placeholder' ? null : (
      <div key={`panel-${title}-${index.toString()}`}>
        <AccordionRoot>
          <StyledAccordionSummary
            expandIcon={<StyledArrowRightIcon />}
            aria-controls="panel1a-content"
          >
            <QuestionTitle>{translate('question', null, qa)}</QuestionTitle>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {analyzeAnswer(translate('answer', null, qa), languageContext)}
          </StyledAccordionDetails>
        </AccordionRoot>
      </div>
    );
  });
  return jsx;
};
const getAccordingDetailJsx = (faqArray, languageContext) => {
  const { translate } = languageContext;
  const jsx = faqArray.map((aq, index) => {
    const title = translate('title', null, aq);
    return title === 'placeholder' ? null : (
      <div key={`panel-header-${index.toString()}`}>
        <QuestionCatalogTitle>{title}</QuestionCatalogTitle>
        {getQuestionsJsx(aq.questions, title, languageContext)}
      </div>
    );
  });
  return jsx;
};

const FAQSections = ({ faqArray, title }) => {
  const languageContext = useContext(LanguageContext);
  const { changeLanguage, translate } = languageContext;
  const { search } = useLocation();
  useEffect(() => {
    // only execute when load
    const lng = getLanguage(search);
    changeLanguage(lng);
  }, [changeLanguage, search]);

  return (
    <Root>
      <Container>
        <Title variant="h6" component="h1">
          {title}
        </Title>
        {getAccordingDetailJsx(faqArray, languageContext)}
        <FooterText>{translate('FAQ.footer', linkObject)}</FooterText>
      </Container>
    </Root>
  );
};

export default FAQSections;

FAQSections.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  faqArray: PropTypes.array,
  title: PropTypes.string.isRequired,
};
FAQSections.defaultProps = {
  faqArray: [],
};
