import React, { useState, useContext, useRef } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { LanguageContext } from 'services/localization/LanguageContext';
import {
  Menu,
  MenuItem,
  ListItemText,
  styled,
  menuItemClasses,
} from '@mui/material';
import ReactToPrint from 'react-to-print';
import CustomRemoveConfirmDialog from './CustomRemoveConfirmDialog';
import PrintableCard from './PrintableCard';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  [`& .MuiPaper-root`]: {
    border: '1px solid #d3d4d5',
    marginTop: '30px',
    overflowY: 'visible',
    overflowX: 'visible',
  },
  [`& .${menuItemClasses.root}`]: {
    marginLeft: '10px',
    marginRight: '10px',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
    fontSize: '12px',
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    },
  },
}));

const StyledMoreVertIcon = styled(MoreVertIcon, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  color: selected ? theme.palette.background.paper : 'rgba(155,155,155,1)',
}));

const pageStyle = `
  @page {
    margin-top: 20px;
  }`;

const TabButtonOptions = ({
  index,
  selectedIndex,
  removeCertificateMenuClicked,
  certificate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = React.useState({
    open: false,
    confirmClick: null,
  });
  const { translate } = useContext(LanguageContext);
  const printableCardRef = useRef();
  const firstName = certificate.firstName.toUpperCase();
  const lastName = certificate.lastName
    ? certificate.lastName.toUpperCase()
    : '';

  const popUpMenue = (event) => {
    event.stopPropagation();

    // on IPAD, if the animation is not end,
    // pop menu will show at the bottom of the screen
    // so we need to show pop menu after the animation is end
    if (selectedIndex !== index) {
      // if it's not the selected one and can scroll
      setTimeout(() => {
        setAnchorEl(event.target);
      }, 550);
    } else {
      setAnchorEl(event.target);
    }
  };

  const closeModal = () => {
    setOpenModal({ open: false });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAfterPrint = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRemoveCertificate = (event) => {
    event.stopPropagation();
    // close menu
    setAnchorEl(null);
    setOpenModal({
      open: true,
      confirmClick: () => {
        setOpenModal({ open: false });
        if (removeCertificateMenuClicked) {
          removeCertificateMenuClicked(certificate.certificateId);
        }
      },
    });
  };

  return (
    <>
      <StyledMoreVertIcon
        selected={selectedIndex === index}
        onClick={(e) => {
          popUpMenue(e, index);
        }}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => (
              <ListItemText primary={translate('pages.cards.printCard')} />
            )}
            content={() => printableCardRef.current}
            onAfterPrint={handleAfterPrint}
            removeAfterPrint
          />
        </MenuItem>
        <MenuItem>
          <ListItemText
            primary={translate('pages.cards.removeCertificate')}
            onClick={(e) => handleRemoveCertificate(e)}
          />
        </MenuItem>
      </StyledMenu>
      {openModal && (
        <CustomRemoveConfirmDialog
          open={openModal.open}
          onClose={closeModal}
          onClick={openModal.confirmClick}
        />
      )}
      <div style={{ display: 'none' }}>
        <PrintableCard
          firstName={firstName}
          lastName={lastName}
          certificateId={certificate.certificateId}
          ref={printableCardRef}
        />
      </div>
    </>
  );
};

export default TabButtonOptions;
TabButtonOptions.propTypes = {
  index: PropTypes.number.isRequired,
  removeCertificateMenuClicked: PropTypes.func.isRequired,
  certificate: PropTypes.shape({
    vaccinationDate: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    certificateId: PropTypes.string,
  }).isRequired,
  selectedIndex: PropTypes.number.isRequired,
};
