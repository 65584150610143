/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import { Typography, Link, styled } from '@mui/material';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import CustomizedButton from 'components/button/CustomizedButton';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import pagesUrl from 'commons/config/PagesUrl';
import { LanguageContext } from 'services/localization/LanguageContext';
import openPDF from 'utility/fileUtility';
import PropTypes from 'prop-types';

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.1em',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

const Message = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const StyledCustomizedButton = styled(CustomizedButton)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Confirmation = ({
  isSubmitted,
  receiveNatPVCByEmail,
  displayPdfReq,
  nationalPVC,
  physicalCard,
  isDigitalCardValid,
  isDigitalCardRequested,
}) => {
  const { translate } = useContext(LanguageContext);

  const openNationalPVC = (e) => {
    e.preventDefault();
    openPDF(nationalPVC);
  };

  useEffect(() => {
    document.getElementById('nav-back').focus();
  }, []);

  return (
    <Root>
      {/* Preventing direct access to this page */}
      {!isSubmitted && <Redirect to={pagesUrl.get('DigitalCards').url} />}
      <Title tabIndex="0">
        {translate('pages.request.confirmation.success')}
      </Title>
      {isDigitalCardRequested && (
        <>
          {!isDigitalCardValid && nationalPVC !== null && (
            <Message tabIndex="0">
              {translate('pages.request.confirmation.accessCardError')}
            </Message>
          )}

          {isDigitalCardValid && (
            <Message tabIndex="0">
              {translate('pages.request.confirmation.accessCard')}
            </Message>
          )}
        </>
      )}

      {physicalCard.requested && physicalCard.completed && (
        <Message tabIndex="0">
          {translate('pages.request.confirmation.receiveCard')}
        </Message>
      )}
      {nationalPVC !== null && (
        <>
          {receiveNatPVCByEmail && (
            <Message tabIndex="0">
              {translate('pages.request.confirmation.receiveCardNational')}
            </Message>
          )}
          {displayPdfReq && nationalPVC && (
            <>
              <Message tabIndex="0">
                {translate('pages.request.confirmation.viewPDF')}{' '}
                <Link
                  variant="body2"
                  component="button"
                  underline="always"
                  onClick={openNationalPVC}
                >
                  PDF
                </Link>
              </Message>
            </>
          )}
        </>
      )}
      <StyledCustomizedButton
        variant="contained"
        color="primary"
        endIcon={<ArrowForwardIcon />}
        component={RouterLink}
        to={pagesUrl.get('DigitalCards').url}
      >
        {translate('pages.request.confirmation.continue')}
      </StyledCustomizedButton>
    </Root>
  );
};

Confirmation.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  receiveNatPVCByEmail: PropTypes.bool.isRequired,
  nationalPVC: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  physicalCard: PropTypes.object,
  displayPdfReq: PropTypes.bool,
  isDigitalCardValid: PropTypes.bool.isRequired,
  isDigitalCardRequested: PropTypes.bool.isRequired,
};

Confirmation.defaultProps = {
  nationalPVC: null,
  physicalCard: null,
  displayPdfReq: false,
};

export default Confirmation;
