import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, styled } from '@mui/material';
import CustomizedButton from 'components/button/CustomizedButton';
import { LanguageContext } from 'services/localization/LanguageContext';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '60px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.1em',
  marginBottom: theme.spacing(3),
  maxWidth: 280,
}));

const Instructions = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const RequestButton = styled(CustomizedButton)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

const NoCertificateTip = ({ linkTo }) => {
  const { translate } = useContext(LanguageContext);

  return (
    <Root>
      <Instructions>
        {translate('pages.cards.getCardInstructions')}
      </Instructions>
      <Typography>{translate('pages.cards.healthCard')}</Typography>
      <RequestButton
        variant="contained"
        color="primary"
        component={RouterLink}
        to={linkTo}
      >
        {translate('pages.cards.requestCard')}
      </RequestButton>
    </Root>
  );
};

export default NoCertificateTip;
NoCertificateTip.propTypes = {
  linkTo: PropTypes.string.isRequired,
};
