/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import links from 'constants/links';
import { LanguageContext } from 'services/localization/LanguageContext';
import { getLanguage, LANG } from '../../../utility/languageUtility';
import './PrivateSection.css';

const linkObject = {
  healthndSeniorsCareWebsite: links.healthndSeniorsCareWebsite,
  contactEmail: links.contactEmail,
  shareHealthLink: links.shareHealthLink,
  immunizationManitobaLink: links.immunizationManitobaLink,
  healthndSeniorsCareWebsiteFr: links.healthndSeniorsCareWebsiteFr,
  shareHealthLinkFr: links.shareHealthLinkFr,
  immunizationManitobaLinkFr: links.immunizationManitobaLinkFr,
  recordLinkEn: links.recordLinkEn,
  recordLinkFr: links.recordLinkFr,
  forResidentsEn: links.forResidentsEn,
  forResidentsFr: links.forResidentsFr,
  recordLinkUnderline: links.recordLinkUnderline,
};

const Root = styled('div')(() => ({
  width: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  fontWeight: 'bold',
}));

const MainSectionTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'mainsection',
})(({ mainsection, theme }) => ({
  paddingBottom: theme.spacing(3),
  fontWeight: 'bold',
  ...(mainsection && {
    textAlign: 'center',
  }),
}));

const ContentText = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '1.5em',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  marginBottom: '20px',
}));

const ListText = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '1.5em',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  marginBottom: '20px',
}));

const ListContainer = styled('ul')(() => ({
  marginTop: '-16px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
}));

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  borderBottom: '25px solid',
  borderBottomColor: theme.palette.primary.dark,
}));

const PrivateSection = ({ privacyPolicy }) => {
  const privacyPolicySections = privacyPolicy.sections;
  const [lang, setLang] = useState(LANG.EN);
  const { translate, changeLanguage } = useContext(LanguageContext);
  const { search } = useLocation();

  useEffect(() => {
    const lng = getLanguage(search);
    changeLanguage(lng);
    setLang(lng);
  }, [changeLanguage, search]);

  const renderSection = (sections, mainSectionIndex) => {
    const jsx = (
      <>
        {sections.map((sectionItem, index) => {
          return Object.keys(sectionItem).map((sectionName) => {
            if (sectionName === 'contents') {
              const translatedContents = sectionItem[sectionName][lang];
              return translatedContents.map(
                (translatedContent, translatedContentIndex) => {
                  if (typeof translatedContent === 'string') {
                    return (
                      <ContentText
                        key={`contents_${mainSectionIndex.toString()}-${index.toString()}-${translatedContentIndex.toString()}`}
                      >
                        {translate('content', linkObject, {
                          content: { [lang]: translatedContent },
                        })}
                      </ContentText>
                    );
                  }

                  if (typeof translatedContent === 'object') {
                    if (translatedContent.list) {
                      return (
                        <ListContainer
                          key={`list_${mainSectionIndex.toString()}-${index.toString()}-${translatedContentIndex.toString()}`}
                        >
                          {translatedContent.list.map((li, liIndex) => {
                            return (
                              <li
                                key={`list_item_${mainSectionIndex.toString()}-${index.toString()}-${translatedContentIndex.toString()}-${liIndex.toString()}`}
                              >
                                <Typography
                                  key={`list_item_p_${mainSectionIndex.toString()}-${index.toString()}-${translatedContentIndex.toString()}-${liIndex.toString()}`}
                                >
                                  {translate('li', linkObject, {
                                    li: { [lang]: li },
                                  })}
                                </Typography>
                              </li>
                            );
                          })}
                        </ListContainer>
                      );
                    }
                  }
                },
              );
            }

            if (sectionName === 'title') {
              return (
                <Title
                  key={`title_${mainSectionIndex.toString()}-${index.toString()}`}
                  variant="h6"
                  component="h1"
                >
                  {translate('title', null, sectionItem[sectionName])}
                </Title>
              );
            }

            return null;
          });
        })}
      </>
    );

    return jsx;
  };

  const renderPrivacyPolicy = (mainSection, mainSectionIndex) => {
    const jsx = Object.keys(mainSection).map((mainSectionObjName) => {
      if (mainSectionObjName === 'title') {
        return (
          <MainSectionTitle
            variant="h5"
            component="h1"
            mainsection={mainSectionIndex === 0}
            key={`mainSectionTitle_${mainSectionIndex.toString()}`}
          >
            {translate(
              mainSectionObjName,
              null,
              mainSection[mainSectionObjName],
            )}
          </MainSectionTitle>
        );
      }

      if (mainSectionObjName === 'section') {
        return renderSection(mainSection[mainSectionObjName], mainSectionIndex);
      }
    });

    return jsx;
  };

  return (
    <Root>
      <StyledBox>
        <LogoContainer>
          <img
            src="/static/media/mb-logo.dcd7fe29.png"
            alt="Manitoba Government"
            width="135"
          />
        </LogoContainer>
        {privacyPolicySections.map((mainSection, index) =>
          renderPrivacyPolicy(mainSection, index),
        )}
      </StyledBox>
    </Root>
  );
};

PrivateSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  privacyPolicy: PropTypes.object,
};

PrivateSection.defaultProps = {
  privacyPolicy: {},
};

export default PrivateSection;
