import React, { useContext } from 'react';
import PageLayout from 'components/page-layout/PageLayout';
import { LanguageContext } from 'services/localization/LanguageContext';
import PrivateSection from './components/PrivateSection';

const PublicPrivatePage = () => {
  const { getTranslationObj } = useContext(LanguageContext);
  const privacyPolicy = getTranslationObj('private');

  return (
    <PageLayout showDrawer={false} alwaysShowLanguageToggle>
      <PrivateSection privacyPolicy={privacyPolicy} />
    </PageLayout>
  );
};
export default PublicPrivatePage;
