import React from 'react';
import mbLogoWhite from 'img/mb-logo-white.png';
import { styled } from '@mui/material';

const Footer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.Footer.backgroundColor,
  height: theme.Footer.height,
  minHeight: theme.Footer.height,
  maxHeight: theme.Footer.height,
  width: '100%',
  marginTop: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Logo = styled('img')(() => ({
  height: '65%',
}));

const PageFooter = () => {
  return (
    <Footer>
      <Logo src={mbLogoWhite} alt="Manitoba Government" height={30} />
    </Footer>
  );
};

export default PageFooter;
