/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-04-02 07:31:32
 */
import React, { createContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AzureOperation from '../common/index';
import CustomizedSnack from '../../components/snack/CustomizedSnack';
import LoadingWithBackDrop from '../../components/page-loading/LoadingWithBackDrop';

function getCertificates(resolve, reject, cancelSrc) {
  const { callApiWithAzureToken, protectedResources } = AzureOperation;
  const params = {
    url: protectedResources.citizen.endpoints.getCitizenCertificates.baseUrl,
    method: protectedResources.citizen.endpoints.getCitizenCertificates.method,
  };
  if (cancelSrc) {
    params.cancelToken = cancelSrc.token;
  }
  // callApiWithAzureToken(params)
  //   .then((result) => {
  //     if (resolve) {
  //       resolve(result);
  //     }
  //   })
  //   .catch((error) => {
  //     if (reject) {
  //       reject(error);
  //     }
  //   });
}
export const CertificatesContext = createContext();
export const CertificatesProvider = ({ children }) => {
  const { protectedResources } = AzureOperation;
  const [citizenCertificates, setCitizenCertificates] = useState([]);
  const [showError, setShowError] = useState({ open: false, message: '' });
  const [showLoading, setShowLoading] = useState(false);
  const refreshCertificates = useCallback((cancelSrc = null) => {
    setShowLoading(false);
    // NOTICE: async operation sometimes return after components unmounted,
    // If it happens, shouldn't call setState because component
    // doesn't exist now and it will cause a warning
    getCertificates(
      (result) => {
        setShowLoading(false);
        if (result) {
          setCitizenCertificates(result || []);
        }
      },
      (error) => {
        setShowLoading(false);
        if (error.message === 'cancel') {
          return;
        }
        setShowError({ open: true, message: error.message });
      },
      cancelSrc,
    );
  }, []);
  useEffect(() => {
    const cancelSrc = axios.CancelToken.source();
    refreshCertificates(cancelSrc);
    return () => {
      cancelSrc.cancel('Cancel because unMounted');
    };
  }, [refreshCertificates]);

  const handleClose = () => {
    setShowError({ ...showError, open: false });
  };
  function removeCertificateFromServer(certificateId, resolve, reject) {
    const params = {
      url: `${protectedResources.citizen.endpoints.removeCitizenCertificate.baseUrl}${certificateId}`,
      method:
        protectedResources.citizen.endpoints.removeCitizenCertificate.method,
    };

    setShowLoading(true);
    AzureOperation.callApiWithAzureToken(params)
      .then((res) => {
        if (!res) {
          setShowLoading(false);
          if (reject) {
            reject();
          }
          return;
        }
        // if success, get the latest one from server and update the ui
        // getCertificates(
        //   (result) => {
        //     if (result) {
        //       setCitizenCertificates(result || []);
        //     }
        //   },
        //   (error) => {
        //     if (error.message === 'cancel') {
        //       return;
        //     }
        //     setShowError({ open: true, message: error.message });
        //   },
        // );
        setShowLoading(false);
        if (resolve) {
          resolve();
        }
      })
      .catch((error) => {
        setShowLoading(false);
        // eslint-disable-next-line no-console
        console.error(error);
        if (reject) {
          reject();
        }
      });
  }
  return (
    <>
      <LoadingWithBackDrop
        open={showLoading}
        handleClick={() => setShowLoading(false)}
      />
      <CustomizedSnack
        message={showError.message}
        open={showError.open}
        handleClose={handleClose}
      />
      <CertificatesContext.Provider
        value={{
          citizenCertificates,
          dateRefreshed: new Date(),
          isLoading: showLoading,
          errorInfo: showError.message,
          removeCertificateFromServer,
          refreshCertificates,
        }}
      >
        {children}
      </CertificatesContext.Provider>
    </>
  );
};

CertificatesProvider.defaultProps = {
  children: null,
};

CertificatesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};
