import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import AzureOperation from 'services/common';
import { LoginContext } from 'services/login/LoginContext';

export const FeatureContext = createContext();

export const FeatureContextProvider = ({ children }) => {
  const loginContext = useContext(LoginContext);
  const isAuth = loginContext.isAuthenticated();
  const [features, setFeatures] = useState([]);

  const getFeatures = async () => {
    try {
      const { protectedResources } = AzureOperation;
      const params = {
        url: protectedResources.settings.endpoints.getFeatures.baseUrl,
        method: protectedResources.settings.endpoints.getFeatures.method,
      };
      const result = await AzureOperation.callApiWithAzureToken(params);
      setFeatures(result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (isAuth) getFeatures();
  }, [isAuth]);

  const checkFeatureEnabled = useCallback(
    (flagKey = '') => {
      const result = features.find((feature) => {
        return feature.featureId === flagKey;
      });
      if (result && result.enabled) return true;

      return false;
    },
    [features],
  );
  const getFeatureFlagMessage = useCallback(
    (flagKey = '') => {
      const result = features.find((feature) => {
        return feature.featureId === flagKey;
      });
      if (result) {
        return result.message;
      }
      return '';
    },
    [features],
  );

  return (
    <FeatureContext.Provider
      value={{
        checkFeatureEnabled,
        getFeatureFlagMessage,
      }}
    >
      {children}
    </FeatureContext.Provider>
  );
};

export const Features = JSON.parse(process.env.REACT_APP_FEATURE_FLAG);

FeatureContextProvider.defaultProps = {
  children: null,
};

FeatureContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};
