import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { useTheme, styled } from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import NavigationBar from '../navigation-bar/NavigationBar';
import DetailNavigationBar from '../navigation-bar/DetailNavigationBar';
import FooterContainer from '../footer/PageFooter';
import NavigationDrawer from '../navigation-bar/NavigationDrawer';

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
}));

const DrawerAndContentContainer = styled('div')(() => ({
  display: 'flex',
  overflow: 'hidden',
  justifyContent: 'center',
  height: '100%',
  flexGrow: 1,
  ...{ height: 'fill-available' },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  minHeight: '100%',
  width: '100%',
  maxWidth: 700,
  // change some styles when nav bar is shown
  [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const ScrollDiv = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'auto',
}));

const PageLayout = (props) => {
  const {
    children,
    showNavigation,
    showFooter,
    showDrawer,
    alwaysShowLanguageToggle,
    detailHeader,
    backButtonClicked,
  } = props;
  const theme = useTheme();
  return (
    <Root>
      {showNavigation && (
        <NavigationBar
          showAddButton={showDrawer}
          showIconButton={showDrawer}
          alwaysShowLanguageToggle={alwaysShowLanguageToggle}
        />
      )}
      {!showNavigation && (
        <DetailNavigationBar
          backButtonClicked={backButtonClicked}
          detailHeader={detailHeader}
        />
      )}
      <DrawerAndContentContainer>
        <Hidden {...theme.navBarBreakpoint.hideDrawer}>
          {showDrawer && (
            <NavigationDrawer
              isOpen
              showCloseButton={false}
              variant="permanent"
            />
          )}
        </Hidden>
        <ScrollDiv>
          <StyledContainer disableGutters id="root-container" maxWidth="fixed">
            {children}
          </StyledContainer>
        </ScrollDiv>
      </DrawerAndContentContainer>

      {showFooter && <FooterContainer />}
    </Root>
  );
};

PageLayout.defaultProps = {
  children: null,
  showNavigation: true,
  showFooter: true,
  showDrawer: true,
  alwaysShowLanguageToggle: false,
  detailHeader: '',
  backButtonClicked: null,
};

PageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node,
  ]),
  showNavigation: PropTypes.bool,
  showFooter: PropTypes.bool,
  showDrawer: PropTypes.bool,
  alwaysShowLanguageToggle: PropTypes.bool,
  detailHeader: PropTypes.string,
  backButtonClicked: PropTypes.func,
};

export default PageLayout;
