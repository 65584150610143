/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  listItemTextClasses,
  keyframes,
} from '@mui/material';
import PropTypes from 'prop-types';
import { LanguageContext } from 'services/localization/LanguageContext';

const ripple = keyframes`to { transform: scale(4); opacity: 0; }`;

const StyledListItem = styled(ListItem)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: '16px',
  paddingBottom: '0px',
  position: 'relative',
  overflow: 'hidden',
  transition: 'background 400ms',
  cursor: 'pointer',
  '& span.ripple': {
    position: 'absolute',
    borderRadius: '50%',
    transform: 'scale(0)',
    backgroundColor: '#ccc',
    animation: `${ripple} 700ms linear`,
  },
}));

const StyledListItemText = styled(ListItemText)(() => ({
  marginTop: '-4px',
  [`& .${listItemTextClasses.primary}`]: {
    font: 'normal normal normal 16px/23px Nunito Sans',
    textAlign: 'left',
    letterSpacing: '0px',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  marginBottom: 'auto',
  minWidth: '24px',
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  paddingLeft: '0px',
  paddingRight: '16px',
  paddingTop: '0px',
}));

const Info = styled('div')(() => ({
  flex: 1,
  alignSelf: 'flex-end',
  marginLeft: '40px',
  padding: '10px 25px',
  borderRadius: '5px',
  backgroundColor: '#F5F5F5',
}));

const CheckboxList = ({ state, setState }) => {
  const { translate } = useContext(LanguageContext);
  const handleChange = (name) => {
    setState((prev) => ({
      ...prev,
      [name]: { ...prev[name], value: !prev[name].value },
    }));
  };

  const createSpan = (prop) => {
    const { elementParent, elementOffset, clientX, clientY } = prop;
    const circle = document.createElement('span');
    const diameter = Math.max(elementOffset.width, elementOffset.height);
    const radius = diameter / 2;

    // eslint-disable-next-line no-multi-assign
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${clientX - elementOffset.left - radius}px`;
    circle.style.top = `${clientY - elementOffset.top - radius}px`;
    circle.classList.add('ripple');

    elementParent.appendChild(circle);
  };

  const deleteSpan = (element) => {
    if (element) element.remove();
  };

  // eslint-disable-next-line no-unused-vars
  const muiRippleEffect = (event) => {
    const { clientX, clientY, currentTarget } = event;

    const getSpanRipple = document.getElementsByClassName('ripple')[0];
    deleteSpan(getSpanRipple);

    const elementOffset = currentTarget.getBoundingClientRect();
    createSpan({
      elementParent: currentTarget,
      elementOffset,
      clientX,
      clientY,
    });
  };

  return (
    <List disablePadding>
      {Object.keys(state).map((name) => {
        const statement = state[name];
        let checked = false;
        if (statement.persistValue !== undefined) {
          checked = statement.persistValue;
        } else {
          checked = statement.default ? true : statement.value;
        }
        return (
          <div key={name}>
            <StyledListItem
              key={name}
              disablePadding
              id={name}
              onClick={(e) => {
                muiRippleEffect(e);
                // Not updating the checkbox if user is clicking a link
                if (!(statement.containsLink && e.target.tagName === 'A')) {
                  handleChange(name);
                }
              }}
            >
              <StyledListItemIcon>
                <StyledCheckbox
                  color="primary"
                  disabled={
                    statement.disabled !== undefined
                      ? statement.disabled
                      : statement.default
                  }
                  tabIndex={statement.default ? '-1' : '0'}
                  checked={checked}
                  name={name}
                  inputProps={{ 'aria-label': translate(statement.ariaLabel) }}
                />
              </StyledListItemIcon>
              <StyledListItemText
                tabIndex={
                  statement.removeTabIndex && !statement.default ? '-1' : '0'
                }
                id={name}
                aria-label={
                  statement.default
                    ? `Checkbox ${translate(
                        statement.label,
                      )} checked by default`
                    : translate(statement.label)
                }
                primary={translate(statement.label)}
              />
            </StyledListItem>
            {statement.value && statement.info && (
              <Info tabIndex="0" aria-label={statement.infoAriaLabel}>
                {statement.info}
              </Info>
            )}
          </div>
        );
      })}
    </List>
  );
};

CheckboxList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
};

export default CheckboxList;
