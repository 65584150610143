/*
 * @Description:
 * @version: 1.0
 * @Author: shaomin fei
 * @Date: 2020-11-04 10:20:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-02 12:21:52
 */
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // Needs to be higher than the appbar z-index
  zIndex: theme.zIndex.drawer + 2,
  color: '#fff',
}));

const LoadingWithBackDrop = (props) => {
  const { open, handleClick } = props;
  return (
    <div>
      <StyledBackdrop open={open} onClick={handleClick}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
    </div>
  );
};
export default LoadingWithBackDrop;
LoadingWithBackDrop.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};
