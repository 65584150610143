import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Root = styled('div')(() => ({
  display: 'flex',
}));

const MainContent = styled('main')(() => ({
  flexGrow: 1,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const DivToolbar = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  marginRight: 0,
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const BackIcon = styled(ArrowBackIcon)(() => ({
  fontSize: '32px',
  fontWeight: 'bold',
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: theme.Header.height,
  backgroundColor: theme.palette.primary.main,
  boxShadow: 'none',
  // use this property to cover the top of permanent drawer
  zIndex: theme.zIndex.drawer + 1,
}));

const DetailHeaderTitle = styled(Typography)(() => ({
  position: 'absolute',
  left: '0',
  width: '100%',
  textAlign: 'center',
  zIndex: '-1',
}));

const DetailNavigationBar = (props) => {
  const { children, detailHeader, backButtonClicked } = props;
  const history = useHistory();

  const handleBackButtonOnClick = () => {
    history.goBack();
  };

  return (
    <>
      <StyledAppBar position="relative">
        <StyledToolbar>
          <BackButton
            id="nav-back"
            color="inherit"
            edge="start"
            onClick={backButtonClicked || handleBackButtonOnClick}
            aria-label="navbar cancel request"
          >
            <BackIcon />
          </BackButton>

          <DetailHeaderTitle tabIndex={0} variant="h6" component="h2">
            {detailHeader}
          </DetailHeaderTitle>
          <div />
        </StyledToolbar>
      </StyledAppBar>
      <Root>
        <MainContent>
          <DivToolbar />
          {children}
        </MainContent>
      </Root>
    </>
  );
};

DetailNavigationBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  detailHeader: PropTypes.string,
  backButtonClicked: PropTypes.func,
};

DetailNavigationBar.defaultProps = {
  children: null,
  detailHeader: '',
  backButtonClicked: null,
};

export default DetailNavigationBar;
