import React, { useContext } from 'react';
import { LanguageContext } from 'services/localization/LanguageContext';
import PageLayout from 'components/page-layout/PageLayout';
import FAQSections from '../../components/faq/FAQSections';

const FAQProxyPage = () => {
  const { getTranslationObj, translate } = useContext(LanguageContext);

  const questionArray = getTranslationObj('FAQ.faqArray');
  return (
    <>
      <PageLayout showDrawer={false} alwaysShowLanguageToggle>
        <FAQSections faqArray={questionArray} title={translate('FAQ.title')} />
      </PageLayout>
    </>
  );
};

export default FAQProxyPage;
