import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, useMediaQuery, useTheme, styled } from '@mui/material';
import { LanguageContext } from 'services/localization/LanguageContext';
import { LANG } from '../../../utility/languageUtility';
import {
  DialogTitle,
  DialogAction,
  Title,
  StyledDialog,
  Content,
} from '../../../components/dialog/CustomDialog';

const WidthOption = {
  desktop: 320,
  mobileNormal: 320,
  // max width of the dialog is 353, and marging is 32
  // considering if the min screen width is 300
  // then the max width of the content is 300-64=236
  // to compatiable more mobile device, set the width to 220
  mobileSmall: 220,
};
let contentWidth = WidthOption.mobileNormal;
const paddingLeftRight = 16;

const StyledDialogTitle = styled(DialogTitle)(() => ({
  marginBottom: '10px',
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: `${contentWidth.toString()}px`,
  padding: `0px ${paddingLeftRight}px`,
  [theme.breakpoints.up('md')]: {
    width: `${WidthOption.desktop.toString()}px`,
  },
  [theme.breakpoints.down('sm')]: {
    width: `${WidthOption.mobileSmall.toString()}px`,
  },
}));

const CustomRemoveConfirmDialog = ({ open, onClose, onClick, ButtonProps }) => {
  const { translate, lang } = useContext(LanguageContext);
  const theme = useTheme();
  let matches = useMediaQuery(theme.breakpoints.up('md'));
  if (matches) {
    contentWidth = WidthOption.desktop;
  }
  matches = useMediaQuery(theme.breakpoints.down('xs'));
  if (matches) {
    contentWidth = WidthOption.mobileSmall;
  }
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      onClose();
    }
  };
  const content = translate('pages.cards.removeConfirmationContent');
  const buttonText = translate('pages.cards.removeConfirmationButton');
  const prefix = translate('pages.cards.removeConfirmationTitlePrefix');
  const shownTitles = [];
  const getTitlesEn = () => {
    shownTitles.push({
      text: `${prefix}`,
    });
  };
  const getTitlesFr = () => {
    shownTitles.push({
      text: `${prefix}`,
    });
  };

  if (lang === LANG.EN) {
    getTitlesEn();
  } else {
    getTitlesFr();
  }
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={onClose} />
      <StyledDialogContent>
        {shownTitles.map((t, index) => {
          return (
            <Title
              key={`${t}_${index.toString()}`}
              variant="h4"
              //   don't hide the text if it's the last line,
              // or the suffix will be cut
            >
              {t.text}
            </Title>
          );
        })}
        {content && shownTitles.length > 0 && <br />}
        {content && <Content variant="h6">{content}</Content>}
      </StyledDialogContent>
      {DialogAction(buttonText, ButtonProps, handleClick)}
    </StyledDialog>
  );
};
export default CustomRemoveConfirmDialog;

CustomRemoveConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  ButtonProps: PropTypes.object,
};

CustomRemoveConfirmDialog.defaultProps = {
  onClick: null,
  ButtonProps: {},
};
