import React, { useContext } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { LanguageContext } from 'services/localization/LanguageContext';
import PageLayout from 'components/page-layout/PageLayout';
import links from 'constants/links';

const MainTitle = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 'bold',
  textDecoration: 'underline',
  textAlign: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  fontSize: 16,
  fontWeight: 'bold',
}));

const TitleInlineContent = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  fontSize: 16,
  fontWeight: 'bold',
  paddingBottom: theme.spacing(3),
}));

const ContentNoPaddingText = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: '1.2em',
  whiteSpace: 'pre-wrap',
}));

const ContentText = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  fontSize: 16,
  lineHeight: '1.2em',
  whiteSpace: 'pre-wrap',
}));

const InLineContentText = styled(Typography)(() => ({
  display: 'inline',
  fontSize: 16,
  lineHeight: '1.2em',
}));

const UnderlineContentText = styled(Typography)(() => ({
  display: 'inline',
  fontSize: 16,
  lineHeight: '1.2em',
  textDecoration: 'underline',
}));

const ListText = styled(Typography)(() => ({
  padding: '0px',
  margin: '0px',
  fontSize: 16,
}));

const ContainerBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
}));

const PrivacyNoticeAndConsent = () => {
  const { translate } = useContext(LanguageContext);

  return (
    <PageLayout showDrawer={false} alwaysShowLanguageToggle>
      <ContainerBox>
        <MainTitle component="h2">{translate('privacy.header')}</MainTitle>
        <MainTitle component="h2">{translate('privacy.subheader')}</MainTitle>
        <ContentText>{translate('privacy.intro')}</ContentText>
        <ContentText>{translate('privacy.intro1')}</ContentText>
        <ContentText>{translate('privacy.intro2')}</ContentText>

        <Title component="h3">{translate('privacy.sections.one.header')}</Title>
        <ContentText>{translate('privacy.sections.one.body1')}</ContentText>
        <ul>
          <li>
            <ListText>{translate('privacy.sections.one.list1')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list11')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list2')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list3')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list4')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list5')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list6')}</ListText>
          </li>
        </ul>
        <ContentNoPaddingText>
          {translate('privacy.sections.one.body11')}
        </ContentNoPaddingText>
        <ul>
          <li>
            <ListText>{translate('privacy.sections.one.list12')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list13')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list14')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list15')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list16')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list17')}</ListText>
          </li>
        </ul>
        <ContentNoPaddingText>
          {translate('privacy.sections.one.body12')}
        </ContentNoPaddingText>
        <ContentText>{translate('privacy.sections.one.body2')}</ContentText>
        <ContentText>{translate('privacy.sections.one.body13')}</ContentText>
        <ContentText>{translate('privacy.sections.one.body14')}</ContentText>

        <ul>
          <li>
            <ListText>{translate('privacy.sections.one.list7')}</ListText>
          </li>
          <li>
            <ListText>{translate('privacy.sections.one.list8')}</ListText>
          </li>
        </ul>
        <ContentText>{translate('privacy.sections.one.body3')}</ContentText>

        <Title component="h3">{translate('privacy.sections.two.header')}</Title>
        <Title>{translate('privacy.sections.two.MBCard.title1')}</Title>

        <ContentText>
          {translate('privacy.sections.two.MBCard.body1')}
        </ContentText>
        <ul>
          <li>
            <ListText>
              {translate('privacy.sections.two.MBCard.list1')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.MBCard.list2')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.MBCard.list3')}
            </ListText>
          </li>
        </ul>
        <ContentText>
          {translate('privacy.sections.two.MBCard.body2')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.two.MBCard.body3')}
        </ContentText>
        <ul>
          <li>
            <ListText>
              {translate('privacy.sections.two.MBCard.list4')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.MBCard.list5')}
            </ListText>
          </li>
        </ul>
        <ContentText>
          {translate('privacy.sections.two.MBCard.body4')}
        </ContentText>
        <ul>
          <li>
            <ListText>
              {translate('privacy.sections.two.MBCard.list6')}
            </ListText>
          </li>
          <li>
            {' '}
            <ListText>
              {translate('privacy.sections.two.MBCard.list12')}
            </ListText>
          </li>
        </ul>
        <ContentText>
          {translate('privacy.sections.two.MBCard.body5')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.two.MBCard.body6')}
        </ContentText>
        <ul>
          <li>
            <ListText>
              {translate('privacy.sections.two.MBCard.list9')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.MBCard.list10')}
            </ListText>
          </li>
        </ul>
        <Title>{translate('privacy.sections.two.NationalPVC.title1')}</Title>
        <ContentText>
          {translate('privacy.sections.two.NationalPVC.body1')}
        </ContentText>
        <ul>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list1')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list2')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list3')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list4')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list5')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list6')}
            </ListText>
          </li>
        </ul>
        <ContentText>
          {translate('privacy.sections.two.NationalPVC.body2')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.two.NationalPVC.body3')}
        </ContentText>
        <ul>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list7')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list8')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list9')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list10')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list11')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list12')}
            </ListText>
          </li>
        </ul>
        <ContentText>
          {translate('privacy.sections.two.NationalPVC.body4')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.two.NationalPVC.body5')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.two.NationalPVC.body6')}
        </ContentText>
        <ul>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list13')}
            </ListText>
          </li>
          <li>
            <ListText>
              {translate('privacy.sections.two.NationalPVC.list14')}
            </ListText>
          </li>
        </ul>

        <TitleInlineContent component="h3">
          {translate('privacy.sections.three.header')}
        </TitleInlineContent>
        <InLineContentText>
          {translate('privacy.sections.three.body1')}
        </InLineContentText>
        <UnderlineContentText>
          {translate('privacy.sections.three.body11')}
        </UnderlineContentText>
        <InLineContentText>
          {translate('privacy.sections.three.body12')}
        </InLineContentText>
        <ContentText>{translate('privacy.sections.three.body2')}</ContentText>
        <ContentText>
          {translate('privacy.sections.three.body3', {
            wpgPhone: links.wpgPhone,
            tollFreePhone: links.tollFreePhone,
            deafAccessPhone: links.deafAccessPhone,
            mbHealthEmail: links.mbHealthEmail,
          })}
        </ContentText>
        <ContentText>{translate('privacy.sections.three.body4')}</ContentText>
        <ContentText>{translate('privacy.sections.three.body5')}</ContentText>

        <Title component="h3">
          {translate('privacy.sections.four.header')}
        </Title>
        <ContentText>{translate('privacy.sections.four.body1')}</ContentText>
        <ContentText>{translate('privacy.sections.four.body2')}</ContentText>
        <ContentText>
          {translate('privacy.sections.four.body3', {
            wpgPhone: links.wpgPhone,
            tollFreePhone: links.tollFreePhone,
            deafAccessPhone: links.deafAccessPhone,
          })}
        </ContentText>

        <Title component="h3">
          {translate('privacy.sections.five.header')}
        </Title>
        <Title component="h3">
          {translate('privacy.sections.five.MBCard.title')}
        </Title>
        <ContentText>
          {translate('privacy.sections.five.MBCard.body1')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.five.MBCard.body2')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.five.MBCard.body3')}
        </ContentText>
        <Title component="h3">
          {translate('privacy.sections.five.NationalPVC.title')}
        </Title>
        <ContentText>
          {translate('privacy.sections.five.NationalPVC.body1')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.five.NationalPVC.body2')}
        </ContentText>
        <ContentText>
          {translate('privacy.sections.five.NationalPVC.body3')}
        </ContentText>
      </ContainerBox>
    </PageLayout>
  );
};

export default PrivacyNoticeAndConsent;
