/* eslint-disable prettier/prettier */
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Box,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
  StepIcon,
  styled,
} from '@mui/material';
import {
  useHistory,
  useLocation,
  Link as RouterLink,
  Route,
} from 'react-router-dom';
import { LanguageContext } from 'services/localization/LanguageContext';
import { LANG_OPTIONS } from 'utility/languageUtility';
import {
  ArrowBackRounded as ArrowBackIcon,
  ArrowForwardRounded as ArrowForwardIcon,
} from '@mui/icons-material';
import CustomizedButton from 'components/button/CustomizedButton';
import CustomDialog from 'components/dialog/CustomDialog';
import links from 'constants/links';
import PageLayout from '../../components/page-layout/PageLayout';
import InformationStep from './steps/InformationStep';
import ConsentStep from './steps/ConsentStep';
import Requests from './steps/Requests';
import AzureOperation from '../../services/common';
import Confirmation from './steps/Confirmation';
import { getCommon } from '../../commons/index';

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: '#EAF0F0',
  padding: theme.spacing(1),
  paddingTop: theme.spacing(2),
}));

const StyledStep = styled(Step)(() => ({
  padding: 0,
}));

const HiddenStep = styled(Step)(() => ({
  maxWidth: 0,
  padding: 0,
}));

const StyledStepIcon = styled(StepIcon)(() => ({
  width: '1.5em',
  height: '1.5em',
}));

const FormContent = styled('form', {
  shouldForwardProp: (prop) => prop !== 'confirmation',
})(({ confirmation, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  flexGrow: 1,
  padding: theme.spacing(3),
  paddingTop: theme.spacing(4),
  [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
    margin: '0px auto',
    marginTop: theme.spacing(2),
  },
  ...(confirmation && {
    [theme.breakpoints.up(theme.navBarBreakpoint.hideDrawerName)]: {
      margin: 0,
    },
  }),
}));

const Buttons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 'auto',
  width: '100%',
  // Buttons in bottom of form
  '& button': {
    minWidth: 115,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

const NavButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap-reverse',
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SubmitLoader = styled(CircularProgress)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const VaxCardRequestStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [submitWarning, setSubmitWarning] = useState({});
  const [nationalPvcError, setNationalPvcError] = useState({});
  const [nationalPVC, setNationalPVC] = useState('');
  const [submitError, setSubmitError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDigitalCardValid, setIsDigitalCardValue] = useState(false);
  const [physicalCardReqCompleted, setPhysicalCardReqCompleted] =
    useState(false);
  const [isPrevButtonClicked, setIsPrevButtonClicked] = useState(false);
  const { translate, lang } = useContext(LanguageContext);
  const history = useHistory();
  const location = useLocation();
  const previousButtonStateRef = useRef();
  //  This stateRef is added so that we can track a specific scenario where
  // submits a request and decided to click the Back Button while the
  // submit is in process
  previousButtonStateRef.current = isPrevButtonClicked;
  /* ********* REQUESTS STEP START ********* */
  const requestsCard = useMemo(() => {
    return {
      digitalCard: {
        value: false,
        // label: 'pages.request.requests.digitalCard',
        // ariaLabel: 'pages.request.requests.digitalCard',
        removeTabIndex: true,
      },
      // physicalCard: {
      //   value: false,
      //   label: 'pages.request.requests.physicalCard',
      //   ariaLabel: 'pages.request.requests.physicalCard',
      //   removeTabIndex: true,
      //   persistValue: false,
      //   disabled: true,
      //   // info: (
      //   //   <Typography variant="subtitle2">
      //   //     {translate('pages.request.requests.info1', {
      //   //       contactEmail: links.contactEmail,
      //   //     })}
      //   //   </Typography>
      //   // ),
      //   // infoAriaLabel: translate('pages.request.requests.info1AriaLabel'),
      // },
    };
  // }, [translate]);
  }, []);
  const requestsPDF = useMemo(() => {
    return {
      sendPDF: {
        value: false,
        label: 'pages.request.requests.sendPDF',
        ariaLabel: 'pages.request.requests.sendPDF',
        removeTabIndex: true,
        info: (
          <Typography variant="subtitle2">
            {translate('pages.request.requests.info2')}
          </Typography>
        ),
        infoAriaLabel: translate('pages.request.requests.info2'),
      },

      viewPDF: {
        value: false,
        label: 'pages.request.requests.viewPDF',
        ariaLabel: 'pages.request.requests.viewPDF',
        removeTabIndex: true,
      },
    };
  }, [translate]);
  const [requestsCardState, setRequestsCardState] = useState(requestsCard);
  const [requestsPDFState, setRequestsPDFState] = useState(requestsPDF);
  /* ********* REQUESTS STEP END ********* */
  /* ********* CONSENT STEP START ********* */
  const { pagesUrl } = getCommon();
  const consentInitialState = useMemo(() => {
    return {
      infoCollection: {
        value: false,
        label: 'pages.request.consent.infoCollection',
        ariaLabel: 'Checkbox first clause',
      },
      infoCollectionTech: {
        value: false,
        label: 'pages.request.consent.infoCollectionTech',
        ariaLabel: 'Checkbox second clause',
      },
      qrScan: {
        value: false,
        label: 'pages.request.consent.qrScan',
        ariaLabel: 'Checkbox third clause',
      },
    };
  }, []);

  const canadianConsentInitialState = useMemo(() => {
    return {
      infoCollection: {
        value: false,
        label: 'pages.request.consent.nationalPVC_Information',
        ariaLabel: 'Checkbox fourth clause',
      },
      infoCollectionTech: {
        value: false,
        label: 'pages.request.consent.nationalPVCInformation_InformationTech',
        ariaLabel: 'Checkbox fifth clause',
      },
    };
  }, []);

  const isCanadianCertificateRequested = () => {
    const found = Object.keys(requestsPDFState).find(
      (name) => requestsPDFState[name].value,
    );
    return !!found;
  };
  
  const isDigitalRequested = () => {
    const found = Object.keys(requestsCardState).find(
      (name) => requestsCardState[name].value,
    );
    return!!found;
  };
  
  const [consentState, setConsentState] = useState(consentInitialState);
  const [canadianConsentState, setCanadianConsentState] = useState(
    canadianConsentInitialState,
  );

  const validateConsent = () => {
    // Verifying that all checkboxes in the consent section are set to true
    let allConsentChecked = false;


    if (isCanadianCertificateRequested()) {
      allConsentChecked =
        Object.keys(canadianConsentState).every(
          (name) => canadianConsentState[name].value,
        );
    }

    if (isDigitalRequested()) {
      allConsentChecked = 
        Object.keys(consentState).every(
          (name) => consentState[name].value,
        );
    }

    if (isDigitalRequested() && isCanadianCertificateRequested()) {
      allConsentChecked = 
        Object.keys(consentState).every(
          (name) => consentState[name].value,
        ) && Object.keys(canadianConsentState).every(
          (name) => canadianConsentState[name].value,
        );
    }

    return allConsentChecked || translate('pages.request.consent.confirm');
  };
  /* ********* CONSENT STEP END ********* */

  /* ********* INFORMATION STEP START ********* */
  const informationCommonInputs = useMemo(() => {
    const now = new Date();
    let dd = now.getDate();
    dd = dd < 10 ? `0${dd}` : dd;
    let mm = now.getMonth() + 1; // Months are 0 indexed
    mm = mm < 10 ? `0${mm}` : mm;
    const yyyy = now.getFullYear();
    const today = `${yyyy}-${mm}-${dd}`;

    return {
      firstName: {
        value: '',
        label: 'pages.request.information.inputs.firstName',
        type: 'text',
        validation: {
          required: true,
          maxLength: 30,
          autoFocus: true,
        },
      },
      lastName: {
        value: '',
        label: 'pages.request.information.inputs.lastName',
        type: 'text',
        validation: {
          required: true,
          maxLength: 30,
        },
      },
      dateOfBirth: {
        value: '',
        label: 'pages.request.information.inputs.dob',
        type: 'date',
        props: {
          InputLabelProps: { shrink: true },
        },
        validation: {
          required: true,
          min: `${yyyy - 130}-${mm}-${dd}`,
          max: today,
        },
      },
    };
  }, []);

  const informationPhinInputs = useMemo(() => {
    return {
      phin: {
        value: '',
        label: 'pages.request.information.inputs.phin',
        mask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        validation: {
          required: true,
          length: 9,
          autoComplete: 'off',
        },
        // customValidate: {
        //   func: validatePhin,
        // eslint-disable-next-line max-len
        //   errorMessage: 'pages.request.information.errors.invalidPhin',
        // },
      },
      registrationNumber: {
        value: '',
        label: 'pages.request.information.inputs.registration',
        mask: [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/],
        validation: {
          required: true,
          length: 6,
          autoComplete: 'off',
        },
      },
    };
  }, []);

  const informationNoPhinInputs = useMemo(() => {
    return {
      clientID: {
        value: '',
        label: 'pages.request.information.inputs.client',
        mask: [/[0-9]/, ...new Array(18).fill(/\d/)],
        validation: {
          required: true,
          minLength: 4,
          maxLength: 19,
          autoComplete: 'off',
        },
        hasTooltip: true,
        TooltipMessage: 'pages.request.information.clientTip',
        labelMsg: 'pages.request.information.errors.client.label',
        requiredMsg: 'pages.request.information.errors.client.empty',
        minLengthMsg: 'pages.request.information.errors.client.minLength',
        maxLengthMsg: 'pages.request.information.errors.client.maxLength',
      },
    };
  }, []);
  const informationInitialState = useMemo(() => {
    return {
      ...informationCommonInputs,
      ...informationPhinInputs,
    };
  }, [informationCommonInputs, informationPhinInputs]);

  const [isNoPhin, setIsNoPhin] = useState(false);
  const [informationState, setInformationState] = useState(
    informationInitialState,
  );

  const toggleNoPhin = (e) => {
    e.preventDefault();
    const noPhin = !isNoPhin;
    setIsNoPhin(noPhin);
    const commonInputState = {};
    Object.keys(informationCommonInputs).forEach((name) => {
      commonInputState[name] = {
        ...informationState[name],
      };
    });
    if (noPhin)
      setInformationState({
        ...commonInputState,
        ...informationNoPhinInputs,
      });
    else
      setInformationState({
        ...commonInputState,
        ...informationPhinInputs,
      });
  };

  /**
   * @description Validates an single input returning an error message if any
   *
   * @param {string} inputName Name of input corresponding to an property in
   *  our form state
   * @param {string|number|bool} [targetValue] An override of the inputs value,
   *  useful when the state has not yet been updated and we want to evaluate
   *  the current value of the input in a particular render cycle.
   *
   * @returns {string} The error message if any.
   */
  const validateInput = (inputName, targetValue) => {
    const input = informationState[inputName];
    let error = '';
    let value;
    // If validating an input on onChange the most recent value will not have
    // been propagated to state, so we can pass one in from the event object
    if (targetValue) {
      value = targetValue;
    } else if (typeof input.value === 'string') {
      value = input.value ? input.value.trim() : input.value;
    } else {
      value = input.value;
    }
    const {
      validation,
      customValidate,
      labelMsg,
      requiredMsg,
      minLengthMsg,
      maxLengthMsg,
    } = input;

    // Validation properties/criteria
    const { required, maxLength, minLength, length, min, max } = validation;
    const label = !labelMsg ? input.label : labelMsg;

    if (required && (!value || value.length === 0)) {
      error = [
        !requiredMsg ? 'pages.request.information.errors.empty' : requiredMsg,
        { label },
      ];
    } else if (minLength && value.length < minLength) {
      error = [
        !minLengthMsg
          ? 'pages.request.information.errors.minLength'
          : minLengthMsg,
        { label, length: minLength },
      ];
    } else if (maxLength && value.length > maxLength) {
      error = [
        !maxLengthMsg
          ? 'pages.request.information.errors.maxLength'
          : validation.maxLengthMsg,
        { label, length: maxLength },
      ];
    } else if (length && value.toString().length !== length) {
      error = [
        'pages.request.information.errors.exactLength',
        { label, length },
      ];
    } else if (min && new Date(value) < new Date(min)) {
      // Min date check
      error = ['pages.request.information.errors.minDate', { date: min }];
    } else if (max && new Date(value) > new Date(max)) {
      // Max date check
      error = ['pages.request.information.errors.maxDate', { date: max }];
    } else if (customValidate && !customValidate.func(value)) {
      error = customValidate.errorMessage;
    }

    return error;
  };

  /**
   * @description Evaluates all inputs in the form state and sets focus on the
   *  invalid input
   *
   * @returns {bool} The validity state of the form (true = valid)
   */
  const validateInformation = () => {
    let isValid = true;
    let firstError;
    // Generating errors and checking if any of the inputs fail
    const formStateWithErrors = {};
    Object.keys(informationState).forEach((name) => {
      const errorMessage = validateInput(name);
      if (errorMessage) {
        isValid = false;
        if (!firstError) {
          firstError = document.getElementById(name);
        }
      }
      formStateWithErrors[name] = {
        ...informationState[name],
        error: errorMessage,
      };
    });
    setInformationState(formStateWithErrors);
    // Focusing on first invalid input if possible
    if (firstError) {
      firstError.focus();
    }
    return isValid;
  };
  /* ********* INFORMATION STEP END ********* */

  const steps = [
    {
      title: translate('pages.request.requests.title'),
      key: 'requests-step',
      content: (
        <Requests
          state={{ requestsCardState, requestsPDFState }}
          setState={{ setRequestsCardState, setRequestsPDFState }}
        />
      ),
    },
    {
      title: translate('pages.request.consent.title'),
      key: 'consent-step',
      content: (
        <ConsentStep
          state={{
            consentState,
            canadianConsentState,
            canadianCertificateRequested: isCanadianCertificateRequested(),
            digitalRequested: isDigitalRequested(),
          }}
          setState={{ setConsentState, setCanadianConsentState }}
        />
      ),
      validate: validateConsent,
    },
    {
      title: translate('pages.request.information.title'),
      key: 'information-step',
      content: (
        <InformationStep
          validateInput={validateInput}
          state={informationState}
          setState={setInformationState}
          isNoPhin={isNoPhin}
          toggleNoPhin={toggleNoPhin}
        />
      ),
      validate: validateInformation,
    },
    {
      title: '',
      stepTitle: translate('pages.request.confirmation.stepTitle'),
      content: '',
      key: 'Submission-step',
    },
  ];

  const resetState = useCallback(() => {
    setActiveStep(0);
    setIsLoading(false);
    setValidationError({});
    setSubmitError({});
    setSubmitWarning({});
    setIsSubmitted(false);
    setConsentState(consentInitialState);
    setInformationState(informationInitialState);
  }, [consentInitialState, informationInitialState]);

  const { pathname } = location;
  const formUrl = pagesUrl.get('VaxCardRequest').url;
  const confirmationUrl = pagesUrl.get('Confirmation').url;
  // The route for the form is not exact because there
  // is a nested route for the confirmation page
  useEffect(() => {
    // If a user is on the confirmation page and they click
    // the back button the state of the form will be reset
    if (pathname !== confirmationUrl) {
      resetState();
      // Replacement for exact, will redirect back to the base form route
      if (pathname !== formUrl) {
        history.replace(formUrl);
      }
    }
  }, [pathname, formUrl, confirmationUrl, history, resetState]);

  const handlePrevStep = () => {
    setIsSubmitted(false);
    setIsPrevButtonClicked(true);
    setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNextStep = () => {
    const { validate } = steps[activeStep];
    const isValid = validate ? validate() : true;
    if (isValid === true) {
      setActiveStep((prev) => prev + 1);
    } else {
      setValidationError({ open: true, message: isValid });
    }
  };

  const clearDialog = (prev) => ({ ...prev, open: false });

  const handleDialogClose = () => {
    // The previous message needs to be retained and managed independent of the
    // open state or an empty dialog will flash on the screen when closing it
    setSubmitWarning(clearDialog);
    setSubmitError(clearDialog);
    setNationalPvcError(clearDialog);
    setValidationError(clearDialog);
  };

  const handleSubmissionSuccess = () => {
    handleDialogClose();
    handleNextStep();
    // To put a check mark on the submission step
    setActiveStep(steps.length);
    history.push(confirmationUrl);
  };

  const handleNationalPVCError = (errorMsg) => {
    setNationalPvcError({
      open: true,
      message: errorMsg,
    });
  };

  const handleAddressError = (
    addressErrorMsg,
    nationalPVCErrorMsg = null,
    showAsHelp = false,
  ) => {
    setSubmitWarning({
      open: true,
      message: addressErrorMsg,
      showAsHelp,
      onClose: () => {
        if (nationalPVCErrorMsg) {
          setSubmitWarning(clearDialog);
          handleNationalPVCError(nationalPVCErrorMsg);
        } else {
          handleDialogClose();
        }
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Performing one last validation check on the
    // form in case a section was somehow skipped
    const formIsValid = steps.every((step, index) => {
      const isValid = step.validate ? step.validate() : true;
      if (isValid !== true) {
        setActiveStep(index);
        setValidationError({ open: true, message: isValid });
        return false;
      }
      return true;
    });
    if (!formIsValid) return false;

    setIsLoading(true);

    let isNationalPVCRequested = false;
    // NatPVC will be generated if user wants to send the PDF by email
    // or display only on the web.
    if (
      requestsPDFState.sendPDF.value === true ||
      requestsPDFState.viewPDF.value === true
    ) {
      isNationalPVCRequested = true;
    }

    const data = {
      ...informationState,
      sendNatPvcByEmail: { value: requestsPDFState.sendPDF.value },
      isNationalPVCRequested: { value: isNationalPVCRequested },
      isPhysicalCardRequested: { value: false }, // requestsCardState.physicalCard.value },
      isDigitalCardRequested: { value: requestsCardState.digitalCard.value}
    };

    const citizen = {};
    Object.keys(data).forEach((fieldName) => {
      citizen[fieldName] = data[fieldName].value;
    });

    const { protectedResources } = AzureOperation;
    const params = {
      url: isNoPhin
        ? protectedResources.citizen.endpoints.registerCitizen.baseUrl
        : protectedResources.citizen.endpoints.addCitizen.baseUrl,
      method: isNoPhin
        ? protectedResources.citizen.endpoints.registerCitizen.method
        : protectedResources.citizen.endpoints.addCitizen.method,
      data: citizen,
      headers: {
        'Accept-Language': LANG_OPTIONS[lang].locale,
      },
    };
    try {
      setIsPrevButtonClicked(false);
      const result = await AzureOperation.callApiWithAzureToken(params);
      if (previousButtonStateRef.current) return false;
      if ((!result || !result.isValid) && (result.nationalPVC === null)) {
        setSubmitError({
          open: true,
          message:
            (result && result.validationMessage) ||
            translate('pages.request.confirmation.error'),
        });
        return false;
      }

      result.warningMessage =
        result.warningMessage || result.physicalCardReachLimitWarning;
      const showAsHelp = !!result.physicalCardReachLimitWarning;

      setIsSubmitted(true);

      // setIsDigitalCardValid(true);
      if (result.isValid){
        setIsDigitalCardValue(true);
      } else {
        setIsDigitalCardValue(false);
      }

      setNationalPVC(result.nationalPVC);
      setPhysicalCardReqCompleted(
        result.warningMessage === null || result.warningMessage === '',
      );

      handleSubmissionSuccess();
      // we can only have either nationalPVCErrorMessage
      // or nationalPVCIneligibleMessage at the same time
      const pvcErrorMsg = result.nationalPVCErrorMessage
        ? translate('pages.request.confirmation.errorCardNational', {
            contactEmail: links.contactEmail,
          })
        : result.nationalPVCIneligibleMessage;

      if (result.warningMessage && pvcErrorMsg) {
        handleAddressError(result.warningMessage, pvcErrorMsg, showAsHelp);
      } else if (result.warningMessage && !pvcErrorMsg) {
        handleAddressError(result.warningMessage, null, showAsHelp);
      } else if (pvcErrorMsg && !result.warningMessage) {
        handleNationalPVCError(pvcErrorMsg);
      }

      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setValidationError({
        open: true,
        message: translate('pages.request.confirmation.error'),
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const prevButton = (
    <CustomizedButton
      key="previous"
      aria-label="previous"
      variant="contained"
      color="primary"
      type="button"
      startIcon={<ArrowBackIcon />}
      onClick={handlePrevStep}
    />
  );

  const nextButton = (
    <CustomizedButton
      key="next"
      aria-label="next"
      variant="contained"
      color="primary"
      type="button"
      endIcon={<ArrowForwardIcon />}
      onClick={handleNextStep}
      disabled = {(requestsPDFState.sendPDF.value === false 
                  && requestsPDFState.viewPDF.value === false
                  && requestsCardState.digitalCard.value === false)}
    />
  );

  const submitButton = (
    <CustomizedButton
      key="submit"
      aria-label="submit Request"
      variant="contained"
      color="primary"
      type="submit"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      {isLoading && <SubmitLoader size={16} />}
      {translate('pages.request.submit')}
    </CustomizedButton>
  );

  const isRequestsKey = (value) => {
    if (String(value).toUpperCase() === 'REQUESTS-STEP') return true;
    return false;
  };
  const isInformationKey = (value) => {
    if (String(value).toUpperCase() === 'INFORMATION-STEP') return true;
    return false;
  };

  const isLastStep = activeStep === steps.length - 1;
  const currentStep = steps[activeStep];
  // can't use title as a param because when it's French, the title is changed
  const isRequestStep = isRequestsKey(currentStep && currentStep.key);
  const isInformationStep = isInformationKey(currentStep && currentStep.key);
  const handleBackButtonClick = () => {
    history.push(pagesUrl.get('DigitalCards').url);
  };

  const formRef = useRef(null);
  const stepperRef = useRef(null);

  let showStepper = true;
  // Only hiding stepper if it is the requests page. If the "current step" is
  // undefined like on the submission page, if should still show it.
  // can't use title as a param because when it's French, the title is changed
  if (isRequestStep) showStepper = false;

  useEffect(() => {
    if (showStepper) {
      stepperRef.current.scrollIntoView();
    } else {
      formRef.current.scrollIntoView();
    }
  }, [activeStep, showStepper]);

  return (
    <PageLayout
      showDrawer={false}
      showNavigation={false}
      detailHeader={translate('layout.nav.appBarWithBackButton.title')}
      backButtonClicked={handleBackButtonClick}
    >
      {showStepper && (
        <StyledStepper
          ref={stepperRef}
          activeStep={activeStep}
          alternativeLabel
          connector={null}
        >
          {steps.map(({ stepTitle, title }, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isLastStep) {
              stepProps.completed = true;
              stepProps.active = false;
            } else labelProps.icon = String(index);

            return Number(index) === 0 ? (
              <HiddenStep key={title} />
            ) : (
              <StyledStep key={title} {...stepProps}>
                <StepLabel
                  aria-label={
                    title
                      ? `${title} step ${index} of ${steps.length - 1}`
                      : `${stepTitle} step ${index} of ${steps.length - 1}`
                  }
                  tabIndex={
                    index === activeStep ||
                    (index === steps.length - 1 && index + 1 === activeStep)
                      ? '0'
                      : '-1'
                  }
                  StepIconComponent={StyledStepIcon}
                  {...labelProps}
                >
                  {stepTitle || title}
                </StepLabel>
              </StyledStep>
            );
          })}
        </StyledStepper>
      )}
      <FormContent
        ref={formRef}
        data-testid={currentStep ? currentStep.title : 'Confirmation'}
        noValidate
        confirmation={pathname === confirmationUrl}
      >
        <Route path={confirmationUrl} exact>
          <Confirmation
            isSubmitted={isSubmitted}
            physicalCard={{
              requested: false, // requestsCardState.physicalCard.value,
              completed: physicalCardReqCompleted,
            }}
            receiveNatPVCByEmail={requestsPDFState.sendPDF.value}
            nationalPVC={nationalPVC}
            displayPdfReq={requestsPDFState.viewPDF.value}
            isDigitalCardValid={isDigitalCardValid}
            isDigitalCardRequested={requestsCardState.digitalCard.value}
          />
        </Route>
        {activeStep < steps.length && !isLastStep && (
          <>
            <Title tabIndex="0" variant="h6">
              {currentStep.title}
            </Title>
            {currentStep.content}
            <Buttons>
              <NavButtons>
                {activeStep > 0 && prevButton}
                {isInformationStep ? submitButton : nextButton}
              </NavButtons>
            </Buttons>
          </>
        )}
      </FormContent>
      <CustomDialog
        title=""
        content={nationalPvcError.message ? nationalPvcError.message : ''}
        open={Boolean(nationalPvcError.open && nationalPvcError.message)}
        onClose={
          nationalPvcError.onClose
            ? nationalPvcError.onClose
            : handleDialogClose
        }
        buttonText={translate('pages.request.ok')}
      />
      <CustomDialog
        title={submitWarning.showAsHelp ? '' : submitWarning.message}
        content={submitWarning.showAsHelp ? submitWarning.message : ''}
        open={Boolean(submitWarning.open && submitWarning.message)}
        onClose={
          submitWarning.onClose ? submitWarning.onClose : handleDialogClose
        }
        buttonText={
          submitWarning.showAsHelp
            ? translate('pages.request.confirmation.help')
            : translate('pages.request.ok')
        }
        ButtonProps={
          submitWarning.showAsHelp && {
            component: RouterLink,
            to: `${pagesUrl.get('FAQ').url}?lang=${lang}`,
            target: '_blank',
          }
        }
      />
      <CustomDialog
        title={
          typeof validationError.message === 'string'
            ? validationError.message
            : ''
        }
        open={Boolean(validationError.open && validationError.message)}
        onClose={handleDialogClose}
        buttonText={translate('pages.request.ok')}
      />
      <CustomDialog
        title={
          typeof submitError.message === 'string' ? submitError.message : ''
        }
        titleSize="small"
        open={Boolean(submitError.open && submitError.message)}
        onClose={handleDialogClose}
        buttonText={translate('pages.request.confirmation.help')}
        ButtonProps={{
          component: RouterLink,
          to: `${pagesUrl.get('FAQ').url}?lang=${lang}`,
          target: '_blank',
        }}
      />
    </PageLayout>
  );
};

export default VaxCardRequestStepper;
