/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-30 23:16:52
 */
import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
// import HistoryIcon from '@mui/icons-material/History';
// eslint-disable-next-line max-len
// import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ReactComponent as PrivacyTip } from '../../img/privacy_tip.svg';

const pagesUrl = new Map();

pagesUrl.set('DigitalCards', {
  url: '/digitalcards',
  showInNavBar: true,
  showName: 'layout.nav.digitalCards',
  group: 1,
  icon: <CreditCardIcon />,
});

pagesUrl.set('VaxCardRequest', {
  url: '/vaxcardrequest',
  showInNavBar: false,
});

pagesUrl.set('Confirmation', {
  url: `${pagesUrl.get('VaxCardRequest').url}/confirmation`,
  showInNavBar: false,
});

pagesUrl.set('PrivacyNoticeAndConsent', {
  url: '/privacyandconsent',
  showInNavBar: false,
  newPage: true,
});

pagesUrl.set('TermsOfUse', {
  url: '/privacy',
  showInNavBar: false,
});
// pagesUrl.set('VenueHistory', {
//   url: '/venuehistory',
//   showInNavBar: true,
//   showName: 'layout.nav.venueHistory',
//   group: 1,
//   icon: <HistoryIcon />,
// });

// pagesUrl.set('OutbreakAlerts', {
//   url: '/outbreakalerts',
//   showInNavBar: true,
//   showName: 'layout.nav.outbreakAlerts',
//   group: 1,
//   icon: <ReportProblemOutlinedIcon />,
// });

// it's privat,only used to redirect to faq when the user clicks this button.
// not included in the router

pagesUrl.set('PrivateNotice', {
  url: '/privacyandconsent',
  showInNavBar: true,
  newPage: true,
  showName: 'layout.nav.privacyNotice',
  group: 1,
  icon: <PrivacyTip fill="#A5A5A5" />,
});
// keeping this for the next menu item "Terms of Use"
// pagesUrl.set('PublicPrivate', {
//   url: '/privacy',
//   showInNavBar: true,
//   newPage: true,
//   showName: 'layout.nav.privacy',
//   group: 1,
//   icon: <PrivacyTip fill="#A5A5A5" />,
// });
pagesUrl.set('FAQInner', {
  url: '/faq',
  showInNavBar: true,
  showName: 'layout.nav.help',
  group: 1,
  icon: <HelpOutlineIcon />,
  newPage: true,
});
// it's public and will be accessed by unlogin user,will redirect to faq

pagesUrl.set('Help', {
  url: '/help',
  showInNavBar: false,
  group: 1,
});

pagesUrl.set('FAQ', {
  url: '/faq',
  showInNavBar: false,
  group: 1,
});

pagesUrl.set('Logout', {
  url: '/logout',
  showInNavBar: true,
  showName: 'layout.nav.logout',
  group: 3,
  icon: <ExitToAppIcon />,
});

export function getGroup(groupId) {
  const result = [];
  pagesUrl.forEach((value) => {
    if (value.group === groupId && value.showInNavBar) {
      result.push(value);
    }
  });

  return result;
}
export default pagesUrl;
