// @flow
/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-27 22:24:09
 */
import { PublicClientApplication } from '@azure/msal-browser';
import { LANG } from 'utility/languageUtility';
/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_AZURE_SIGHIN,
    editProfile: process.env.REACT_APP_AZURE_EDIT_PROFILE,
    forgotPassword: '',
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AZURE_AUTHORITY_SIGNIN_SIGNUP,
    },
    forgotPassword: {
      authority: '',
    },
    editProfile: {
      authority: process.env.REACT_APP_AZURE_AUTHORITY_EDIT_PROFILE,
    },
  },
  authorityDomain: process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN,
};
/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to
 * any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [`${process.env.REACT_APP_AZURE_AUTHORITY_API_SCOPE}`],
  extraQueryParameters: { ui_locales: LANG.EN },
};
/**
 * Add here the endpoints and scopes when obtaining an access token
 * for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  citizen: {
    endpoints: {
      getCitizen: {
        method: 'get',
        baseUrl: 'citizen',
      },
      addCitizen: {
        method: 'post',
        baseUrl: 'citizen',
      },
      registerCitizen: {
        method: 'post',
        baseUrl: 'citizen/client/register',
      },
      removeCitizenCertificate: {
        method: 'post',
        baseUrl: 'citizen/remove/',
      },
      getCitizenCertificates: {
        method: 'get',
        baseUrl: 'citizen',
      },
    },
    scopes: [`${process.env.REACT_APP_AZURE_AUTHORITY_API_SCOPE}`], // e.g. api://xxxxxx/access_as_user
  },
  verify: {
    endpoints: {
      getCertificate: {
        method: 'get',
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}certificate/`,
      },
    },
  },
  settings: {
    endpoints: {
      getFeatures: {
        method: 'get',
        baseUrl: 'v2/settings/features',
      },
    },
  },
};
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: false,
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);
