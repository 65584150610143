import React from 'react';
import PropTypes from 'prop-types';
import { MobileStepper, styled, mobileStepperClasses } from '@mui/material';

const StyledMobileStepper = styled(MobileStepper)({
  backgroundColor: 'inherit',
  [`& .${mobileStepperClasses.dot}`]: {
    height: 12,
    width: 12,
    marginLeft: 5,
    marginRight: 5,
  },
});

const CustomStepper = ({ startIndex, count, selectedIndex }) => {
  let indexInThisStepper = -1;
  if (startIndex <= selectedIndex && startIndex + count > selectedIndex) {
    indexInThisStepper = selectedIndex - startIndex;
  }
  return (
    <StyledMobileStepper
      variant="dots"
      position="static"
      steps={count}
      activeStep={indexInThisStepper}
    />
  );
};

export default CustomStepper;
CustomStepper.propTypes = {
  startIndex: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};
