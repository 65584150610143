import React, { useContext, useEffect, useState } from 'react';
import PageLayout from 'components/page-layout/PageLayout';
import { Paper, Tabs, useMediaQuery, Button } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import scrollIntoView from 'scroll-into-view';
import SwipeableViews from 'react-swipeable-views';
import { LanguageContext } from 'services/localization/LanguageContext';
import NoCertificateTip from 'pages/digital-cards/components/NoCertificateTip';
import CardTab from 'pages/digital-cards/components/CardTab';
import RemoveFailed from 'pages/digital-cards/components/RemoveFailed';
import { CertificatesContext } from 'services/certificates/CertificatesProvider';
import { getCommon } from 'commons/index';

import CustomStepper from './components/CustomStepper';
import TabButtonOptions from './components/TabButtonOptions';
import CustomDialog from '../../components/dialog/CustomDialog';
import {
  Features,
  FeatureContext,
} from '../../services/common/azure/FeatureContext';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const TabOptionContent = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'fullWidth' &&
    prop !== 'selectionFollowsFocus' &&
    prop !== 'textColor' &&
    prop !== 'indicator',
})({});

const TabsPaper = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
}));

const FirstName = styled('span')(() => ({
  maxWidth: '240px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const LastName = styled('span')(() => ({
  width: '14px',
}));

const CardContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  paddingTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  flexGrow: 1,
}));

const TabsRoot = styled(Tabs)(() => ({
  display: 'flex',
  overflowX: 'auto',
  overflowY: 'hidden',
  position: 'relative',
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  display: 'flex',
  margin: '10px',
  minWidth: '150px',
  minxHeight: '25px',
  borderRadius: '5px',
  boxShadow: '0 2px 5px rgb(0 0 0 / 0.2)',
  color: theme.palette.common.black,
  ...(selected && {
    backgroundColor: '#004F9A',
    color: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: '#004F9A',
    },
  }),
}));

let alreadyShowTip = false;

const DigitalCards = () => {
  const {
    citizenCertificates,
    dateRefreshed,
    isLoading,
    errorInfo,
    removeCertificateFromServer,
    refreshCertificates,
  } = useContext(CertificatesContext);
  const { translate } = useContext(LanguageContext);
  const hasCertificates = citizenCertificates.length > 0;
  const { pagesUrl } = getCommon();
  const [selectedTab, setSelectedTab] = useState({
    index: 0,
    certificate: hasCertificates ? citizenCertificates[0] : null,
  });

  const [openDeleteErrorModal, setOpenDeleteErrorModal] = React.useState(false);

  const [openTipAfterLogin, setOpenTipAfterLogin] = React.useState({
    open: false,
    message: '',
  });
  const { checkFeatureEnabled, getFeatureFlagMessage } =
    useContext(FeatureContext);
  useEffect(() => {
    if (!checkFeatureEnabled(Features.TIP_AFTER_LOGIN)) {
      return;
    }
    const message = getFeatureFlagMessage(Features.TIP_AFTER_LOGIN);
    if (!message) {
      return;
    }
    if (alreadyShowTip) {
      return;
    }
    setOpenTipAfterLogin({
      open: true,
      message,
    });
    alreadyShowTip = true;
  }, [checkFeatureEnabled, getFeatureFlagMessage]);
  useEffect(() => {
    refreshCertificates();
  }, [refreshCertificates]);
  useEffect(() => {
    if (hasCertificates && selectedTab.index === 0) {
      // first time load, set value to certificate
      setSelectedTab({ index: 0, certificate: citizenCertificates[0] });
    }
    if (hasCertificates && selectedTab.index >= citizenCertificates.length) {
      // if the last one is removed, then we should select the first one
      setSelectedTab({ index: 0, certificate: citizenCertificates[0] });
    }
  }, [citizenCertificates, hasCertificates, selectedTab.index]);

  const handleChange = (event, newValue) => {
    // click the selected one, nothing happen
    if (newValue === selectedTab.index) {
      return;
    }
    if (event) {
      event.stopPropagation();
      // not from swipe view
      const moveToElement = event.target;
      scrollIntoView(
        moveToElement,
        {
          time: 200,
          align: {
            left: 0.2,
            top: 1,
            leftOffset: 10,
          },
        },
        () => {
          setSelectedTab({
            index: newValue,
            certificate: citizenCertificates[newValue],
          });
        },
      );
    } else {
      setSelectedTab({
        index: newValue,
        certificate: citizenCertificates[newValue],
      });
    }
  };

  const getCardActionsJsx = () => {
    if (errorInfo) {
      return null;
    }
    if (!isLoading) {
      if (citizenCertificates.length === 0) {
        return <NoCertificateTip linkTo={pagesUrl.get('VaxCardRequest').url} />;
      }
    }
    return null;
  };

  const removeCertificate = (certificateId) => {
    let certificate = '';
    if (certificateId) {
      const splitUrl = certificateId.split('?');
      const szId = splitUrl[1].split('=');
      // eslint-disable-next-line prefer-destructuring
      certificate = szId[1];
    }
    removeCertificateFromServer(certificate, null, () => {
      setOpenDeleteErrorModal(true);
    });
  };

  const transformName = (certificate) => {
    if (!certificate) {
      return '';
    }
    const firstName = certificate.firstName.toUpperCase();
    let lastNameInitial = '';
    if (certificate.lastName) {
      lastNameInitial = certificate.lastName[0].toUpperCase();
    }
    return `${firstName} ${lastNameInitial}.`;
  };
  const theme = useTheme();
  // not show scroll button on mobile
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const getStepper = () => {
    const maxPerLine = 10;
    let lines = parseInt(citizenCertificates.length / maxPerLine, 10);
    if (lines * maxPerLine < citizenCertificates.length) {
      lines += 1;
    }
    const countArray = [];
    for (let i = 1; i <= lines; ) {
      if (i * maxPerLine > citizenCertificates.length) {
        countArray.push(citizenCertificates.length % maxPerLine);
      } else {
        countArray.push(maxPerLine);
      }
      i += 1;
    }
    return countArray.map((count, index) => {
      return (
        <CustomStepper
          key={`stepper_${index.toString()}`}
          startIndex={index * maxPerLine}
          count={count}
          selectedIndex={selectedTab.index}
        />
      );
    });
  };
  return (
    <PageLayout>
      <RemoveFailed
        open={openDeleteErrorModal}
        closeModal={() => setOpenDeleteErrorModal(false)}
        content={translate('pages.cards.removeFialed')}
      />
      <CustomDialog
        open={openTipAfterLogin.open}
        onClose={() =>
          setOpenTipAfterLogin((state) => ({
            open: false,
            message: state.message,
          }))
        }
        title={openTipAfterLogin.message}
        buttonText="OK"
      />
      <Root>
        {citizenCertificates.length > 0 && (
          <TabsPaper square>
            <TabsRoot
              value={
                selectedTab.index >= citizenCertificates.length
                  ? 0
                  : selectedTab.index
              }
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={matches}
              aria-label="scrollable auto tabs"
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {citizenCertificates.map((certificate, index) => {
                return (
                  <TabOptionContent key={String(index)}>
                    <StyledButton
                      selected={selectedTab.index === index}
                      onClick={(e) => handleChange(e, index)}
                      endIcon={
                        <TabButtonOptions
                          key={`${certificate.firstName} ${
                            certificate.lastName
                          }-${index.toString()}`}
                          label={transformName(certificate)}
                          index={index}
                          removeCertificateMenuClicked={removeCertificate}
                          certificate={certificate}
                          selectedIndex={selectedTab.index}
                        />
                      }
                    >
                      <FirstName>
                        {certificate.firstName.toUpperCase()}
                      </FirstName>
                      &nbsp;
                      <LastName>{`${
                        certificate.lastName
                          ? certificate.lastName[0].toUpperCase()
                          : ''
                      }.`}</LastName>
                    </StyledButton>
                  </TabOptionContent>
                );
              })}
            </TabsRoot>
          </TabsPaper>
        )}

        {hasCertificates && (
          <CardContainer
            role="tabpanel"
            id={`scrollable-auto-tabpanel-${selectedTab.index}`}
            aria-labelledby={`scrollable-auto-tab-${selectedTab.index}`}
          >
            <SwipeableViews
              index={selectedTab.index}
              style={{ maxWidth: '100%' }}
              onChangeIndex={(index) => handleChange(null, index)}
              enableMouseEvents
            >
              {citizenCertificates.map((cert) => (
                <CardTab
                  key={cert.certificateId}
                  certificate={cert}
                  dateRefreshed={dateRefreshed}
                />
              ))}
            </SwipeableViews>
            {citizenCertificates.length > 1 && getStepper()}
          </CardContainer>
        )}
        {getCardActionsJsx()}
      </Root>
    </PageLayout>
  );
};

export default DigitalCards;
