import React from 'react';
import { Typography, styled, dialogClasses } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PropTypes from 'prop-types';

const TitleRoot = styled(MuiDialogTitle)(() => ({
  padding: '0px',
  display: 'flex',
  height: '20px',
  alignItems: 'center',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
  marginLeft: 'auto',
  marginRight: '0px',
  padding: '0px',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    borderRadius: '8px',
    padding: theme.spacing(1),
  },
}));

const ContentText = styled(Typography)(() => ({
  fontSize: '1.2em',
  textAlign: 'center',
}));

// eslint-disable-next-line react/prop-types
const DialogTitle = ({ onClose }) => {
  return (
    <TitleRoot>
      {onClose ? (
        <CloseButton aria-label="close" onClick={onClose}>
          <HighlightOffIcon />
        </CloseButton>
      ) : null}
    </TitleRoot>
  );
};
const DialogContent = styled(MuiDialogContent)((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const RemoveFailed = ({ open, content, closeModal }) => {
  return (
    <>
      <StyledDialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={closeModal} />
        <DialogContent>
          <ContentText variant="h4">{content}</ContentText>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
export default RemoveFailed;
RemoveFailed.propTypes = {
  content: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
