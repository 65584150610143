import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { SnackbarContent, styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
}));

const CustomizedSnack = (props) => {
  const { vertical, horizontal, message, handleClose, open, colour } = props;
  const snackBarStyle = {
    fontFamily: 'Fira Sans',
    // fontWeight: 'bold',
    fontSize: '20px',
    background: colour || null,
    minWidth: '250px',
  };
  return (
    <Root>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent
          style={snackBarStyle}
          message={message}
          action={<CloseIcon onClick={handleClose} />}
        />
      </Snackbar>
    </Root>
  );
};
CustomizedSnack.propTypes = {
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  colour: PropTypes.string,
};

CustomizedSnack.defaultProps = {
  vertical: 'top',
  horizontal: 'center',
  colour: 'gray',
};

export default CustomizedSnack;
