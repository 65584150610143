import React from 'react';
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  styled,
  dialogClasses,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PropTypes from 'prop-types';

const CloseButton = styled(IconButton)(({ theme }) => ({
  padding: '0px',
  color: theme.palette.grey[500],
}));

const StyledDialogTitle = styled(MuiDialogTitle)(() => ({
  marginLeft: 'auto',
  marginRight: '8px',
  marginTop: '8px',
  padding: '0px',
}));

export const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'issmall',
})(({ issmall }) => ({
  textAlign: 'center',
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  fontSize: '18px',
  fontFamily: 'Nunito Sans',
  color: '#222222',
  opacity: 1,
  // retain \r\n in the string, but merge multi white-space
  whiteSpace: 'pre-line',
  ...(issmall && {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
  }),
}));

export const Content = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.down(380)]: {
    font: 'normal normal normal 12px/20px Nunito Sans',
  },
  [theme.breakpoints.down(330)]: {
    font: 'normal normal normal 11px/20px Nunito Sans',
  },
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  fontFamily: 'Nunito Sans',
  color: '#222222',
  opacity: 1,
}));

const ContentRoot = styled(DialogContent)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ButtonConfirm = styled(Button)(({ theme }) => ({
  minWidth: '136px',
  minHeight: '30px',
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: '600',
  fontFamily: 'Nunito Sans',
  background: '#004F9A 0% 0% no-repeat padding-box',
  borderRadius: '3px',
  opacity: 1,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 14px #00000029',
    borderRadius: '6px',
    opacity: 1,
    padding: '0px',
    maxWidth: '440px',
  },
}));

const ActionRoot = styled(DialogActions)(() => ({
  justifyContent: 'center',
}));

const ContentPadding = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

// eslint-disable-next-line react/prop-types
export const DialogTitle = ({ onClose }) => {
  return (
    <StyledDialogTitle>
      {onClose ? (
        <CloseButton aria-label="close" onClick={onClose}>
          <HighlightOffIcon />
        </CloseButton>
      ) : null}
    </StyledDialogTitle>
  );
};
export const DialogAction = (buttonText, ButtonProps, handleClick) => {
  return buttonText ? (
    <ActionRoot>
      <ButtonConfirm
        {...ButtonProps}
        autoFocus
        onClick={handleClick}
        color="primary"
        variant="contained"
        disableRipple
      >
        {buttonText}
      </ButtonConfirm>
    </ActionRoot>
  ) : null;
};

const CustomDialog = ({
  title,
  content,
  open,
  buttonText,
  onClose,
  onClick,
  ButtonProps,
  titleSize,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      onClose();
    }
  };

  return (
    <>
      <StyledDialog onClose={onClose} open={open}>
        <DialogTitle onClose={onClose} />
        <ContentPadding
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="dialogContent"
        >
          <ContentRoot>
            {title && (
              <Title
                id="dialogContent"
                aria-hidden
                tabIndex="0"
                variant="h4"
                issmall={titleSize === 'small'}
              >
                {title}
              </Title>
            )}
            {content && title && <br />}
            {content && (
              <Content id="dialogContent" aria-hidden tabIndex="0" variant="h6">
                {content}
              </Content>
            )}
          </ContentRoot>
          {DialogAction(buttonText, ButtonProps, handleClick)}
        </ContentPadding>
      </StyledDialog>
    </>
  );
};

export default CustomDialog;

CustomDialog.propTypes = {
  title: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  ButtonProps: PropTypes.object,
  titleSize: PropTypes.string,
};

CustomDialog.defaultProps = {
  title: null,
  content: null,
  onClick: null,
  buttonText: null,
  ButtonProps: {},
  titleSize: 'normal',
};
