import React, { useContext } from 'react';
import { Box, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import mbLogo from 'img/mb-logo-white.png';
import mbLogoGreen from 'img/mb-logo.png';
import { LanguageContext } from 'services/localization/LanguageContext';

const Title = styled(Typography)(() => ({
  paddingTop: 2,
  fontSize: 14,
}));

const NavBarLogo = ({ isWhiteLogo }) => {
  const { translate } = useContext(LanguageContext);

  return (
    <Box>
      {isWhiteLogo ? (
        <img src={mbLogo} alt="Manitoba Government" width={135} />
      ) : (
        <img src={mbLogoGreen} alt="Manitoba Government" width={135} />
      )}

      <Title>{translate('layout.logoText')}</Title>
    </Box>
  );
};

export default NavBarLogo;
NavBarLogo.propTypes = {
  isWhiteLogo: PropTypes.bool,
};
NavBarLogo.defaultProps = {
  isWhiteLogo: false,
};
