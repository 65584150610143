import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import { LanguageContext } from 'services/localization/LanguageContext';
import { LoginContext } from 'services/login/LoginContext';
import CustomDialog from 'components/dialog/CustomDialog';

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  color: '#A5A5A5',
}));

const StyledListItemText = styled(ListItemText)(() => ({
  color: 'black',
}));

const LinkButton = styled('button')(() => ({
  border: 'none',
  width: '100%',
  backgroundColor: 'transparent',
  padding: 0,
}));

const LinkContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(
  ({ selected }) =>
    selected && {
      backgroundColor: '#ccc',
    },
);

const NavigationBarItem = (props) => {
  const { text, Icon, linkTo, onClick, newPage, endIcon } = props;
  const location = useLocation();
  const loginContext = useContext(LoginContext);
  const pathname = location ? location.pathname : '';
  const [selected, setSelected] = useState(linkTo && pathname === linkTo);
  const { translate, lang } = useContext(LanguageContext);
  const [openErrorModal, setOpenErrorModal] = useState({
    open: false,
    content: '',
  });

  const itemClick = (e, redirectTo) => {
    if (translate(text) === translate('layout.nav.logout')) {
      e.preventDefault();
      loginContext.logout(() => {
        // logout will report error when following steps:
        // 1. login 2. open another tab and get into the same page
        // 3. go back to the first page, logout
        // 4. go to the second page, logout
        // 5. error occure.
        // the reason is we use localstorage, when logout first time,
        // the b2c enters redirect-login state
        // if you logout at this time, because the first redirect-login request
        // is still in progress, then the error occurs

        setOpenErrorModal({
          open: true,
          content: translate('pages.request.confirmation.error'),
        });
      });
    }
    if (!newPage && linkTo) {
      // when open the new tab, the selected button should be the previous one.
      setSelected(true);
    }

    if (pathname === redirectTo) {
      e.preventDefault();
    }
    if (onClick) {
      onClick(text);
    }
  };

  useEffect(() => {
    if (linkTo) {
      setSelected(pathname === linkTo);
    }
  }, [pathname, linkTo]);

  const target = newPage ? '_blank' : '_self';
  const linkWithLanguage = newPage ? `${linkTo}?lang=${lang}` : linkTo;

  const linkContent = (
    <LinkContent selected={selected}>
      <ListItem button>
        <StyledListItemIcon>{Icon}</StyledListItemIcon>
        <StyledListItemText primary={translate(text)} />
        {endIcon}
      </ListItem>
    </LinkContent>
  );

  const menuLink = linkTo ? (
    <Link
      to={linkWithLanguage}
      onClick={(e) => itemClick(e, linkTo)}
      target={target}
    >
      {linkContent}
    </Link>
  ) : (
    <LinkButton type="button" onClick={(e) => itemClick(e, linkTo)}>
      {linkContent}
    </LinkButton>
  );

  return (
    <>
      <CustomDialog
        content={openErrorModal.content}
        open={openErrorModal.open}
        onClose={() => setOpenErrorModal({ open: false })}
      />
      {menuLink}
    </>
  );
};

NavigationBarItem.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  newPage: PropTypes.bool,
  endIcon: PropTypes.element,
};

NavigationBarItem.defaultProps = {
  Icon: null,
  linkTo: null,
  onClick: null,
  newPage: false,
  endIcon: null,
};

export default NavigationBarItem;
