/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-30 10:18:55
 */

// the createMuiTheme will cause some warnings,
// according to official doc, use unstable_createMuiStrictModeTheme instead
// or remove strickmode
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';

const theme = createMuiTheme();

const LuminaTheme = createMuiTheme({
  Banner: {
    fontWeight: 'bold',
  },
  Header: {
    height: '80px',
    background: '#1a4e89',
  },
  Footer: {
    height: '40px',
    backgroundColor: '#1B2355',
  },
  palette: {
    background: {
      blue: 'blue',
      default: '#EAF0F0',
      paper: '#FFF',
    },
    borders: 'rgba(0, 0, 0, 0.1)',
    primary: {
      main: '#004F9A',
    },
  },
  Loading: {
    width: '300px',
    height: '100px',
  },
  typography: {
    fontFamily: [
      'Nunito Sans',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'sans-serif',
    ].join(','),
    subtitle1: {
      fontSize: '0.74em',
    },
    button: {
      textTransform: 'none',
    },
  },
  drawer: {
    width: 400,
  },
  navBarBreakpoint: {
    // when width<960px, hide the nav bar
    // the follow two params have the same meaning,
    //  just adapte to different usage.
    hideDrawer: { lgDown: true },
    hideDrawerButton: { lgUp: true },
    hideDrawerName: 'sm',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: '#4EB028',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '20px',
          padding: theme.spacing(2),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: theme.spacing(1),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0px',
          display: 'flex',
          height: '36px',
          alignItems: 'center',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
  },
});

export default LuminaTheme;
