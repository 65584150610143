import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  Tooltip,
  IconButton,
  Grid,
  Link,
  styled,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import { Help as HelpIcon } from '@mui/icons-material';
import CustomDialog from 'components/dialog/CustomDialog';
import { LanguageContext } from 'services/localization/LanguageContext';
import TextMaskCustom from '../../../components/inputs/TextMaskCustom';
import { isAndroid, isChrome, isIosSafari } from '../../../utility/osUtility';

const Form = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const inputStyle = (theme) => {
  return {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 320,
    marginRight: 'auto',
    marginLeft: 'auto',
    flex: 1,
    maxWidth: '100%',
  };
};

const InputTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    width: '120%',
  },
  '& label.MuiInputLabel-root': {
    width: '90%',
  },
  ...inputStyle(theme),
}));

const InputGrid = styled(Grid)(({ theme }) => inputStyle(theme));

const StyledTooltip = styled(Tooltip)(() => ({
  marginTop: 14,
}));

const InformationStep = ({
  validateInput,
  state,
  setState,
  isNoPhin,
  toggleNoPhin,
}) => {
  const { translate } = useContext(LanguageContext);
  const [helpNote, setHelpNote] = useState(false);
  /**
   * @description Updates internal state of input and validates input if it has
   *  an error
   *
   * @param {event} e
   */
  const handleFormChange = (e) => {
    const { name, value } = e.target || e;

    let error = '';
    if (state[name].error) {
      error = validateInput(name, value);
    }
    setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        error,
      },
    }));
  };
  const dateFocused = (event) => {
    event.currentTarget.click();
  };
  const handleHelpNoteClose = () => {
    setHelpNote(false);
  };
  const handleHelpNoteOpen = () => {
    setHelpNote(true);
  };

  const [dateValue, setDateValue] = useState(new Date());

  const form = Object.keys(state).map((name) => {
    const input = state[name];
    const InputProps = {}; // Material-UI input component
    // add spellCheck to disable Smart punctuatin of IOS11+.
    // Smart punctuation will change ' to another character
    const inputProps = {
      ...input.validation,
      spellCheck: 'false',
    }; // HTML input element

    if (input.label === 'pages.request.information.inputs.registration') {
      input.value = input.value.toUpperCase();
    }
    if (input.mask) {
      InputProps.inputComponent = TextMaskCustom;
      inputProps.mask = input.mask;
    }
    if (input.type === 'date') {
      if (isAndroid() && isChrome()) {
        inputProps.contentEditable = 'true';
      }

      inputProps.onChange = (e) => {
        handleFormChange(e);
        // For some iPhones on iOS 14.x switching to a date input can cause
        // content with "position: fixed" to scroll off screen. This resets the
        // viewport to focus on the content
        window.scrollTo(0, 0);
      };
    }

    const formatDate = (value) => {
      if (!value) return undefined;
      const day = value.toLocaleString('default', { day: '2-digit' });
      const month = value.toLocaleString('default', { month: '2-digit' });
      const year = value.toLocaleString('default', { year: 'numeric' });
      return `${year}-${month}-${day}`;
    };

    const textField =
      input.type === 'date' && isIosSafari() ? (
        <LocalizationProvider key={name} dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            mask="mm/dd/YYYY"
            id={name}
            name={name}
            type={input.type}
            label={translate(input.label)}
            value={dateValue}
            onChange={(newdate) => {
              const value = formatDate(newdate);
              setDateValue(newdate);
              handleFormChange({ name, value });
            }}
            renderInput={(params) => (
              <InputTextField {...params} {...input.props} />
            )}
          />
        </LocalizationProvider>
      ) : (
        <InputTextField
          key={name}
          variant="outlined"
          id={name}
          name={name}
          value={input.value}
          label={translate(input.label)}
          type={input.type}
          error={Boolean(input.error)}
          helperText={translate(input.error)}
          onChange={handleFormChange}
          // passing validation props like length/required etc. to base input
          inputProps={inputProps}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={InputProps}
          {...input.props}
          // we only test chrom on android, it doesn't work for firefox
          // although we don't need to take care of firefox or
          // other browsers on android, it would be better
          // to keep the default action for browsers we don't test.
          onFocus={
            input.type === 'date' && isAndroid() && isChrome()
              ? dateFocused
              : null
          }
        />
      );

    if (!input.hasTooltip) return textField;
    return (
      <InputGrid container key={textField.key}>
        <Grid item xs={10}>
          {textField}
        </Grid>
        <Grid item xs={2}>
          <StyledTooltip
            title={translate(input.TooltipMessage)}
            onClick={handleHelpNoteOpen}
          >
            <IconButton>
              <HelpIcon />
            </IconButton>
          </StyledTooltip>
        </Grid>
      </InputGrid>
    );
  });

  useEffect(() => {
    document.getElementById('nav-back').focus();
  }, []);

  return (
    <>
      <Typography tabIndex="0" variant="subtitle2">
        {translate('pages.request.information.instructions', {
          instructions: isNoPhin
            ? 'pages.request.information.phinInstructions'
            : 'pages.request.information.noPhinInstructions',
          clickLink: (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link href="#" onClick={toggleNoPhin} key="toggleLink">
              {translate('pages.request.information.click')}
            </Link>
          ),
        })}
      </Typography>
      <Form>{form}</Form>
      <CustomDialog
        title={translate('pages.request.information.clientHelpNote')}
        titleSize="small"
        open={helpNote}
        onClose={handleHelpNoteClose}
        buttonText={translate('pages.request.ok')}
      />
    </>
  );
};

InformationStep.propTypes = {
  validateInput: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  isNoPhin: PropTypes.bool.isRequired,
  toggleNoPhin: PropTypes.func.isRequired,
};

export default InformationStep;
